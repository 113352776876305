import {css, jsx} from '@emotion/react';
import styled from '@emotion/styled';
import {mq} from '@facepaint';

export const mt = css({marginTop: 12});

export const baseSize = css({
  width: 260,
  maxWidth: '100%',
});

const fontFamily = css({fontFamily: 'Helvetica Neue,Helvetica,Roboto,Arial,sans-serif'});

export const Base = styled('div')(
  {
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    borderBottomLeftRadius: 5,
    transitionDuration: '100ms',
    transitionTimingFunction: 'ease-in',
    marginBottom: '1.12em',
    transitionProperty: 'all',
    label: {
      display: 'flex',
      alignItems: 'center',
      marginRight: 'auto',
      span: {
        display: 'inline-flex',
        marginRight: 14,
        marginLeft: 14,
      },
    },
  },
  fontFamily,
  baseSize,
  props => ({width: props.sizeVariant === 'fullwidth' ? '100%' : 'auto'}),
);

export const Input = styled('input')({
  backgroundColor: 'white',
  borderTopLeftRadius: 'inherit',
  borderTopRightRadius: 'inherit',
  borderBottomRightRadius: 'inherit',
  borderBottomLeftRadius: 'inherit',
  height: 39,
  fontSize: 15,
  width: '100%',
  border: '1px solid rgb(235,235,235)',
  padding: '0 1em',
  '&:focus': {border: '1px solid #a5a5a5'},
});

export const Button = styled('button')(
  {
    backgroundColor: 'black',
    color: 'white',
    cursor: 'pointer',
    height: 50,
    width: '100%',
    fontWeight: 'bold',
    transition: 'all 0.2 ease',
    ':hover, &.focus-visible': {
      backgroundColor: 'white',
      border: '1px solid black',
      color: 'black',
    },
  },
  props => ({width: props.sizeVariant === 'fullwidth' ? '100%' : 'auto'}),
);

export const box = css({
  display: 'flex',
  flexDirection: 'column',
});

export const Label = styled('label')({
  marginLeft: '0.65em',
  marginBottom: '0.3em',
  display: 'block',
});

export const ButtonLink = styled('button')(
  {
    color: '#002EFF',
    fontSize: 16,
    ':hover, &.focus-visible': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
  props => ({width: props.fullwidth ? '100%' : 'auto'}),
);

export const FormFooter = styled('div')({
  display: 'flex',
  borderTop: '1px solid #eaeaea',
  width: '100%',
  height: 77,
  alignItems: 'center',
  justifyContent: 'center',
});

export const stack = css({
  justifyContent: 'space-between',
  alignItems: 'center',
  height: 'inherit',
});

export const height = css({
  [mq.small]: {height: 300},
  [mq.medium]: {height: 400},
  [mq.large]: {height: 400},
});

export const ActionButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
