import {css, jsx} from '@emotion/react';
import styled from '@emotion/styled';

const stylesByVariantCloseButton = {
  darkGray:{
    normal: css`
      color: #fff;
      background: rgba(70,70,70,0.2);
    `,
    hover: css`
      background: rgba(19,19,19,0.2);
    `,
  },
  lightGray:{
    normal: css`
            color: #fff;
            background: rgba(255,255,255,0.2);
      `,
    hover: css`
        background: rgba(204, 204, 204, 0.2);
    `,
  },
};

export const Separator = styled.div`
  width: 1px;
  background: rgba(255, 255, 255, 0.25);
  border-radius: 9999px;
  margin-right: 8px;
  height: 17px;
  top: 2px;
  position: relative;
`;

export const ActionButtonStyled = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  position: relative;
  font-weight: 500;

  &:hover {
    cursor: pointer;
    ${({theme, variant}) => css`
      background: ${theme.buttons[variant].backgroundColorHover};
      cursor: ${variant === 'disabled' ? 'not-allowed' : 'pointer'};
      color: ${theme.buttons[variant].colorHover || theme.buttons[variant].color};
      border: ${theme.buttons[variant].borderHover || theme.buttons[variant].border || 'none'};
    `}
  }

  /*Remove normal focus, and add back
  for when button is handled using keyboard.
  */
  &:focus {
    outline: none;
  }
  &.focus-visible {
    box-shadow: 0 0 0 3px #537fc3;
  }

  ${({variant, theme, fontSize}) => css`
    color: ${theme.buttons[variant].color};
    background-color: ${theme.buttons[variant].backgroundColor};
    padding: ${theme.buttons[variant].padding};
    border-radius: ${theme.buttons[variant].borderRadius};
    border: ${theme.buttons[variant].border};
    box-shadow: ${theme.buttons[variant].boxShadow || 'none'};
    line-height: ${theme.buttons[variant].lineHeight || '1.5'};
    margin: ${theme.buttons[variant].margin || '1rem 1rem 0rem 0rem'};
    font-size: ${fontSize ? fontSize : theme.buttons[variant].fontSize || '0.9rem'};
  `}

  ${({hasRightIcon}) => hasRightIcon && css`
      padding-right: 55px;
  `}

  ${({hasLeftIcon}) => hasLeftIcon && css`
      padding-left: 45px;
  `}
`;

export const IconWrapper = styled.div`
  position: absolute;
  right: 8px;
  margin-right: 8px;
  display: flex;
  align-items: center;

  ${({position}) => position === 'left' && css`
    left: 8px;
  ` || css`
      right: 8px;
  `}
`;


export const CloseButtonStyled = styled.button`
 display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 1em;
  position: relative;

  font-size: ${({fontSize}) => fontSize ? fontSize : '0.9em'};
  &:hover {
    cursor: pointer;
    ${({theme, variant}) => stylesByVariantCloseButton[variant].hover}
  }

  &:focus {
    outline: none;
  }


  &.focus-visible {
    box-shadow: 0 0 0 3px #537fc3;
  }

  margin-bottom: 0;
  margin-left: 0;

  ${({theme, variant}) => stylesByVariantCloseButton[variant].normal}
  &:active{
    ${({theme, variant}) => stylesByVariantCloseButton[variant].normal}
  }

  border-radius: 50%;
  width: 2.42rem;
  height: 2.42rem;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.1rem;
  margin: 0;
`;
