import React, {useState} from 'react';
import PropTypes from 'prop-types';
import CVEditExperienceType from './edit-experience-type/edit-experience-type';

import {Header, Desc, DescriptionEdit, TitleEditWrapper} from './cv-edit-styles';
import {EducationFormElements, HonoraryOrVolunteerElements, LanguageFormElements, ProjectFormElements, WorkExperiencesFormElements} from './form-elements';
import {TopBar} from '@routes/my-education/containers/my-education-cv/cv-components';
import {MainHeader, ContentWrapper} from '../cv-styles';
import {StyledInput} from '@components/form-input/form-input-styles';
import {i18n} from '@src/i18n';


export const CVEdit = ({
  initialExperienceData,
  initialDescription,
  initialTitle,
  cvID,
  profile,
  onSave,
  onSaved,
}) => {
  const [experienceData, setExperienceData] = useState(initialExperienceData);

  const [description, setDescription] = useState(initialDescription);
  const [title, setTitle] = useState(initialTitle);

  const updateData = (kind, changedData) => {
    const copyOfStoredExperienceData = experienceData;

    copyOfStoredExperienceData[kind] = changedData;
    setExperienceData(copyOfStoredExperienceData);
  };

  return (
    <>
      <TopBar
        manageMode="edit"
        title={title}
        onClickEditSave={() => onSave({
          description,
          experienceData,
          title,
        })}
      />
      <ContentWrapper mode="edit">
        <MainHeader>
          {profile.data.fullname}
        </MainHeader>

        <Header>
          {i18n('cv.form-elements.name')}
        </Header>
        <TitleEditWrapper>
          <StyledInput
            name="cv-name"
            label=""
            input={{value: title}}
            onChange={evt => setTitle(evt.target.value)}
            value={title}
          />
        </TitleEditWrapper>

        <Header>{i18n('globals.about')}</Header>
        <Desc>{i18n('cv.form-elements.description')}</Desc>
        <DescriptionEdit
          key="description"
          rows="8"
          cols="80"
          value={description}
          onChange={evt => {
            setDescription(evt.target.value);
          }}
        />
        <CVEditExperienceType
          title={i18n('cv.experience-kind-headers.work')}
          experienceKind="work"
          description={i18n('cv.experience-kind-descriptions.work')}
          addNewBtnTxt={i18n('cv.add-new-of-kind.work')}
          onAlteredData={updateData}
          manageExperienceFormElementsComponent={
            WorkExperiencesFormElements
          }
          initialExperienceData={experienceData.work || []}
        />

        <CVEditExperienceType
          title={i18n('cv.experience-kind-headers.education')}
          description={i18n('cv.experience-kind-descriptions.education')}
          experienceKind="education"
          addNewBtnTxt={i18n('cv.add-new-of-kind.education')}
          onAlteredData={updateData}
          manageExperienceFormElementsComponent={EducationFormElements}
          initialExperienceData={experienceData.education || []}
        />

        <CVEditExperienceType
          title={i18n('cv.experience-kind-headers.project')}
          experienceKind="project"
          description={i18n('cv.experience-kind-descriptions.project')}
          addNewBtnTxt={i18n('cv.add-new-of-kind.project')}
          onAlteredData={updateData}
          manageExperienceFormElementsComponent={ProjectFormElements}
          initialExperienceData={experienceData.project || []}
        />

        <CVEditExperienceType
          title={i18n('cv.experience-kind-headers.elected')}
          experienceKind="elected"
          description={i18n('cv.experience-kind-descriptions.elected')}
          addNewBtnTxt={i18n('cv.add-new-of-kind.elected')}
          onAlteredData={updateData}
          manageExperienceFormElementsComponent={
          HonoraryOrVolunteerElements
        }
          initialExperienceData={experienceData.elected || []}
        />

        <CVEditExperienceType
          title={i18n('cv.experience-kind-headers.language')}
          description={i18n('cv.experience-kind-descriptions.language')}
          addNewBtnTxt={i18n('cv.add-new-of-kind.language')}
          experienceKind="language"
          onAlteredData={updateData}
          manageExperienceFormElementsComponent={LanguageFormElements}
          initialExperienceData={experienceData.language || []}
        />
      </ContentWrapper>
    </>
  );
};

CVEdit.propTypes = {
  initialExperienceData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  initialDescription: PropTypes.string.isRequired,
  profileEditCV: PropTypes.func.isRequired,
  cvID: PropTypes.number.isRequired,
  onSave: PropTypes.func.isRequired,
  onSaved: PropTypes.func.isRequired,
};

CVEdit.defaultProps = {};


export default CVEdit;
