import {css, jsx} from '@emotion/react';
import styled from '@emotion/styled';
import {mqMax} from '@facepaint';


export const Header = styled.h3`
  color: #494949;
  font-weight: 400;
  margin-top: 0;
`;

export const Description = styled.p`
  width: 60%;
  color: #222222;

  ${mqMax.medium}{
    width: 100%;
  }
`;


export const LeftPartButtons = styled.div`
  display: flex;

  ${mqMax.medium}{
    flex-direction: column-reverse;

    .submit-btn{
      margin-bottom: 0.55em;
    }
  }
`;

export const LeftPart = styled.div`
  display: flex;
`;

export const RightPartButtons = styled.div`
  margin-left: auto;
  ${mqMax.medium} {
    margin-left: auto;
    margin-right: auto;
    margin-top: 1.5em;
    font-size: 0.825em;
  }
`;

export const RightPart = styled.div`
  margin-left: auto;

  ${mqMax.medium} {
    margin-left: 0;
    margin-top: 1em;
    align-self: flex-start;
  }
`;

export const Wrapper = styled.div`
  border: 1px solid #e5e5e5;
  margin-bottom: 60px;
  padding: 25px;
`;

export const AddNewBtn = styled.button`
  cursor: pointer;
  color: #1573e6;
  margin: 30px 0;
  display: flex;
  align-items: center;

  &:hover {
    color: #2262cd;
  }
`;

export const AddNewBtnContent = styled.div`
  margin-bottom: 2px;
`;

export const AddNewIcon = styled.i`
  ::after {
    content: '+';
  }

  font-size: 1.5em;
  background-color: #f4f8fc;
  border: 1px solid #ceddeb;
  font-style: initial;
  color: #1573e6;
  border-radius: 3px;
  margin-right: 20px;
  padding: 10px 15px;

  &:hover {
    color: #2262cd;
    background-color: #e0ebf7;
  }
`;

export const StyledForm = styled.form``;

export const ManageExperienceWrapperHeader = styled.h4`
  color: #494949;
  font-weight: 450;
  margin-bottom: 7px;
  font-size: 1.025em;
`;

export const ExperienceListWrapper = styled.div`
  display: flex;
  margin-bottom: 10px;
  min-height: 72px;
  align-items: center;

  ${mqMax.medium}{
    flex-direction: column;
  }
`;

export const FormHelp = styled.p`
  margin-top: 0;
  font-size: 0.8em;
  max-height: 200px;
`;

export const CancelButton = styled.button`
  &:hover {
    cursor: pointer;
  }
`;

export const DeleteButton = styled.button`
  background-color: #e0383e;
  color: white;
  padding: 0.8em;
  border-radius: 8px;
  font-size: 0.9em;
  float: right;
  border: none;
  white-space: nowrap;
  :hover,
  :active,
  :hover:active {
    background-color: #cc2a30;
  }
`;

export const ActionButtonsWrapper = styled.div`
  margin-top: 2em;
  margin-bottom: 30px;
  display: flex;

  ${mqMax.medium}{
    flex-direction: column;
    align-items: center;
  }
`;

export const EditBtnExperience = styled.button`
  padding: 8px 12px;
  background-color: rgb(255, 255, 255);
  border: 1px solid #1573e6;
  color: #1473e6;
  margin-left: 1.5em;
  white-space: nowrap;

  &:hover {
    color: #fff;
    background-color: #1573e6;
    cursor: pointer;
  }

  ${mqMax.medium}{
    margin-left: 0;
    margin-bottom: 1.5em;
  }
`;

export const EditBtnExperienceIcon = styled.button`
  margin-right: 10px;
  color: inherit;
`;

export const ExperienceHeader = styled.h3`
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 500;
  font-size: 1em;
`;
export const NoExperiencesFound = styled.p`
  font-style: italic;
  margin-top: 7px;
`;

export const ExperienceDetails = styled.p`
  margin-top: 0;
  margin-bottom: 0;
  font-size: 0.9em;
  color: #494949;
  margin-left: auto;

  ${props =>
  props.last
    ? css`
          margin-bottom: 10px;
        `
    : ''
  }
`;
