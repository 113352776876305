import {useEffect, useState, useRef} from 'react';
import axios, {CancelTokenSource} from 'axios';
import {backendUrl} from '@config';
import {i18n} from '@src/i18n';
import * as styles from './search-bar-styles';
import {getRouteWithParams} from '@utils/routes.utils';
import {employees} from '@routes/routes.manifest';
import {useHistory} from 'react-router-dom';
import {Section} from './search-bar-styles';
const cancelMsg = 'Operation canceled by the user.';

const SearchBar = ({
  term,
  showMenu,
}) => {
  const source = useRef();
  const history = useHistory();

  const [result, setResult] = useState([]);
  const [status, setStatus] = useState(0);


  useEffect(() => {
    if (term.length <= 2) {
      setStatus(0);
    }else {
      if (source.current) {
        source.current.cancel(cancelMsg);
      }
      const tokenSource = axios.CancelToken.source();

      source.current = tokenSource;
      setStatus(1);
      axios.request({
        url: `${backendUrl}/persons/search?mode=ac&n=100&term=${term}`,
        method: 'GET',
        withCredentials: true,
        cancelToken: tokenSource.token,
      }).then(({data}) => {
        setStatus(2);
        setResult(data);
      })
        .catch(error => {
          console.error(error);
          setResult([]);
        });
    }
  }, [term]);

  const openItem = (item, linkType) => {
    showMenu(false);
    if (item.category === 'Enhet' || linkType === 'company') {
      const orgId = item.url.split('/').splice(-1);

      if (orgId[0].includes('pid=')) {
        localStorage.setItem('orgId', orgId[0].split('#')[0]);
        history.push(getRouteWithParams(employees.employees, {orgId: orgId[0].split('#')[0]}));
      }else {
        localStorage.setItem('orgId', orgId[0]);
        history.push(getRouteWithParams(employees.employees, {orgId: orgId[0]}));
      }
      console.log(orgId);
    }else {
      const orgId = item.url.split('/').splice(-1)[0].split('#')[0];

      localStorage.setItem('orgId', orgId);
      history.push(getRouteWithParams(employees.employeePreview, {
        orgId,
        userName: item.username,
      }));
    }
  };

  const orgs = Boolean(status === 2 && result?.length !== 0) && result.filter(res => res.category === 'Enhet') || [];
  const persons = Boolean(status === 2 && result?.length !== 0) && result.filter(res => res.category === 'Person') || [];

  return (
    <div>
      {status === 0 && (
        <>
          {i18n('globals.enter-text')}
        </>
      ) || status === 1 && (
        <div>
          {i18n('globals.searching')}
        </div>
      ) || status === 2 && (
        <styles.SearchResult>
          {orgs.length !== 0 && (
            <styles.Section>
              <h4>{i18n('search.organisations')}</h4>
              {orgs.map(res => (
                <styles.Item>
                  <styles.CompanyIco>
                    {res.label.slice(0, 1)}
                  </styles.CompanyIco>
                  <styles.CompanyName onClick={() => openItem(res)}>
                    {res.label}
                  </styles.CompanyName>
                </styles.Item>
              ))}
            </styles.Section>
          )}
          {persons.length !== 0 && (
            <styles.Section>
              <h4>{i18n('search.persons')}</h4>
              {persons.map(res => (
                <styles.Item>
                  <>
                    <styles.PersonIco>
                      {res.label.slice(0, 1)}
                    </styles.PersonIco>
                    <styles.Name>
                      <div onClick={() => openItem(res)}>
                        {res.label.split(' (')[0]}
                      </div>
                      <styles.Company
                        onClick={() => openItem(res, 'company')}
                      >
                        (
                        {res.label.split(' (')[1]}
                      </styles.Company>
                    </styles.Name>

                  </>
                </styles.Item>
              ))}
            </styles.Section>
          )}
          {[...persons, ...orgs].length === 0
            && i18n('globals.no-hits-for-search-x', {functionArgs: {x: term}})}
        </styles.SearchResult>
      )}
    </div>
  );
};

export default SearchBar;
