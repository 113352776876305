import Cookies from 'js-cookie';
import * as T from '@types/load.types';

import * as authActions from '@actions/auth.actions';

const initialState = {
  authProfile: undefined,
  newPwd: undefined,
  stage: 'username',
  loginAttempts: 0,
  overtaken: null,
  transfered: {status: T.LoadStatuses.NOT_VERIFIED},
  secondLogin: false,
  secondData: undefined,
  sessionId: Cookies.get('tg-visit') || 'sd',
  authStatus: {},
};

const auth = (state = initialState, action) => {
  switch (action.type) {
  case authActions.AUTH_LOGIN_REQUEST:
    return {
      ...state,
      authProfile: undefined,
      stage: 'username',
      sessionId: undefined,
      authStatus: null,
    };

  case authActions.AUTH_LOGOUT_SUCCESS:
  case authActions.AUTH_UNAUTHORIZED:
    return {
      ...state,
      authProfile: undefined,
      stage: 'username',
      sessionId: undefined,
    };
  case authActions.AUTH_LOGIN_FAILURE:
    return {
      ...state,
      authProfile: undefined,
      stage: 'username',
      sessionId: undefined,
      authStatus:{
        isLoggedIn: false,
        loginFailed: true,
        lastCheck: new Date(),
      },
    };

  case authActions.AUTH_CHECK_LOGIN_METHOD_REQUEST:
    return {
      ...state,
      stage: 'username',
    };
  case authActions.AUTH_LOGIN_ATTEMTPS_REQUEST:
    return {
      ...state,
      loginAttempts: state.loginAttempts + 1,
    };
  case authActions.AUTH_CHECK_LOGIN_METHOD_SUCCESS:
    return {
      ...state,
      stage: action.payload.message,
    };
  case authActions.AUTH_BART_USER_SUCCESS:
    return {
      ...state,
      overtaken: action.payload.data,
    };
  case authActions.AUTH_CHECK_LOGIN_METHOD_FAILURE:
    return {
      ...state,
      stage: 'username',
    };
  case authActions.AUTH_LOGIN_GOTO_ON:
    return {
      ...state,
      secondLogin: false,
      secondData: undefined,
    };
  case authActions.AUTH_LOGIN_SUCCESS:
    return {
      ...state,
      authProfile: action.payload.authProfile,
      sessionId: action.payload.authProfile.session_id,
      secondLogin: false,
      startUrl: action.payload.start_url,
    };
  case authActions.AUTH_LOGIN_SUCCESS_CHOICE:
    return {
      ...state,
      authProfile: action.payload.authProfile,
      sessionId: action.payload.authProfile.session_id,
      secondLogin: true,
      secondData: {
        username: action.payload.username,
        password: action.payload.password,
      },
    };
  case authActions.AUTH_LOGIN_NEW_PASSWORD_REQUEST:
    return {
      ...state,
      newPwdStatus: -1,
      newPwd: null,
    };
  case authActions.AUTH_LOGIN_NEW_PASSWORD_SUCCESS:
    return {
      ...state,
      newPwdStatus: 2,
      newPwd: action.payload.message,
    };
  case authActions.AUTH_LOGIN_NEW_PASSWORD_FAILURE:
    return {
      ...state,
      newPwdStatus: 1,
      newPwd: action.payload.message,
    };
  case authActions.AUTH_TRANSFER_USER:
    return {
      ...state,
      transfered: {status: T.LoadStatuses.NOT_VERIFIED},
    };
  case authActions.AUTH_TRANSFER_USER_SUCCESS:
    return {
      ...state,
      transfered: {status: T.LoadStatuses.LOADED},
    };
  case authActions.AUTH_CLOSE_RESET_PASSWORD_FORM:
    return {
      ...state,
      newPwdStatus: -1,
    };
  case authActions.AUTH_USER_IS_CONFIRMED_LOGGED_IN:
    return {
      ...state,
      authStatus:{
        ...state.authStatus,
        isLoggedIn: true,
        lastCheck: new Date(),
      },
    };
  case authActions.AUTH_USER_IS_CONFIRMED_NOT_LOGGED_IN:
    return {
      ...state,
      authStatus:{
        isLoggedIn: false,
        lastCheck: new Date(),
      },
    };

  default:
    return state;
  }
};

export default auth;
