import React from 'react';
import {css} from '@emotion/react';
import {createPortal} from 'react-dom';
import {AnimatePresence, motion} from 'framer-motion';

const AnimateSlideUpOnLoad = ({key, exitAnimating, children, ...props}) => (
  <AnimatePresence>
    {!exitAnimating
    && (
      <motion.div
        key={key}
        css={css`
          position: absolute;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 10000;
          background: #fff;
          margin: 0;
          overflow: auto;
        `}
        initial={{top: '100%'}}
        animate={{top: '0%'}}
        exit={{top: '100%'}}
        transition={{
          ease: 'easeOut',
          duration: 0.6,
        }}
        {...props}
      >
        {children}
      </motion.div>
    )}
  </AnimatePresence>
);


export default AnimateSlideUpOnLoad;


export const AnimateSlideUpOnLoadTransparent = ({key, show, children, ...props}) =>
  createPortal(
    <AnimatePresence>
      {show
        && (
          <>
            <motion.div
              key={key}
              css={css`
              position: absolute;
              left: 0;
              width: 100%;
              height: 100%;
              z-index: 1000;
              background: rgba(51, 51, 51, 0.75);
              margin: 0;
              overflow: auto;
            `}
              initial={{opacity: 0}}
              animate={{opacity: 1}}
              exit={{opacity: 0}}
              transition={{
                bounce: 0,
                duration: 0.3,
                type: 'spring',
              }}
              {...props}
            />
            <motion.div
              css={css`
                background-color: transparent;
                overflow: auto;
                position: fixed;
                z-index: 1001;
                left: 0;
                top: 0;
                height: 100%;
                width: 100%;
            `}
              initial={{top: '100%'}}
              animate={{top: '0%'}}
              exit={{top: '100%'}}
              transition={{
                bounce: 0,
                duration: 0.3,
                type: 'spring',
              }}

            >
              {children}
            </motion.div>
          </>
        )}
    </AnimatePresence>,
    document.querySelector('#sliders'),
  );

