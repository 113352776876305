import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {
  CoopByggmix,
  CoopElektro,
  CoopExtra,
  CoopMarked,
  CoopMatkroken,
  CoopMega,
  CoopObs,
  CoopObsbygg,
  CoopPrix,
  Coop,
  Bkk,
} from '@src/assets/svg/logo/logo';

import {themes} from '@utils/themes.utils';

import {connect} from 'react-redux';

import {getBrandId} from '@selectors/profile.selectors';

const ThemedLogo = ({brandId, className}) => {
  let Logo;

  switch (Number(brandId)) {
  default:
  case themes.Faghandel:
  case themes.Medlemsvalgtportal:
    Logo = Coop;
    break;
  case themes.ObsHypermarked:
    Logo = CoopObs;
    break;
  case themes.ObsBygg:
  case themes.ExtraBygg:
    Logo = CoopObsbygg;
    break;
  case themes.Extra:
    Logo = CoopExtra;
    break;
  case themes.Matkroken:
    Logo = CoopMatkroken;
    break;
  case themes.CoopMarked:
    Logo = CoopMarked;
    break;
  case themes.CoopPrix:
    Logo = CoopPrix;
    break;
  case themes.CoopMega:
    Logo = CoopMega;
    break;
  case themes.Byggmix:
    Logo = CoopByggmix;
    break;
  case themes.Elektro:
    Logo = CoopElektro;
    break;
  }

  return <Logo className={classNames(className, `theme${brandId}`)} />;
};

ThemedLogo.propTypes = {
  brandId: PropTypes.number,
  className: PropTypes.string,
};

ThemedLogo.defaultProps = {
  brandId: null,
  className: null,
};

const mapStateToProps = state => ({brandId: getBrandId(state)});

export default connect(mapStateToProps)(ThemedLogo);
