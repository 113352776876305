import {faTimes as faTimesSolid} from '@fortawesome/pro-solid-svg-icons';
import {faTimes as faTimesRegular} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {i18n} from '@src/i18n';
import React from 'react';
import {CloseButtonStyled} from './buttonStyles';


export const CloseButton = ({iconVariant = 'solid', variant, ...props}) => (
  <CloseButtonStyled
    className="close-btn"
    ariaLabel={i18n('globals.close')}
    title={i18n('globals.close')}
    variant={variant}
    {...props}
  >
    <FontAwesomeIcon
      icon={iconVariant === 'solid' || !iconVariant ? faTimesSolid : faTimesRegular}
    />
  </CloseButtonStyled>
);
