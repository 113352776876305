import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

import {Redirect} from 'react-router-dom';

import * as authActions from '@actions/auth.actions';

import AuthService from '../utils/auth-service';

import {screenreaderOnlyMinxin} from '@styles/mixins';

import {version, buildHash, allowedLanguages} from '@src/config';
import LoginFormTwo from '../components/LoginFormTwo/LoginFormTwo';
import ChangePasswordForm from '../components/LoginFormTwo/ChangePasswordForm';

import backgroundOslo1 from '../../../assets/images/background-olso-1.jpg';

import {i18n} from '@src/i18n';

import '../styles/login-container.scss';
import {ReactComponent as SnapperLogoDimmed} from '@src/assets/svg/logo/snapper_dimmed.svg';

import {
  Wrapper,
  LeftPart,
  RightPart,
  BgImage,
  LanguageSelectWrapper,
  MainHeader,
  Warning,
  LoginFormWrapper,
  Footer,
  FooterLogo,
  FooterTextWrapper,
  FooterMainText,
  FooterSecondaryText,
} from './styles/styles';
import LanguageSelect from '../components/LanguageSelect/LanguageSelect';
import {getAuthStatus} from '@selectors/auth.selectors';

const isIE = false || !!document.documentMode;

class LoginContainer extends Component {
  state = {showSection: 'login'};

  componentDidMount() {
    const {to, sessionId, loginAttempts, authStatus} = this.props;

    console.log('to', to);
    console.log('sessionId', sessionId);
    console.log('authStatus', authStatus);
    console.log('authAttempts', loginAttempts);
    if (!authStatus.isLoggedIn && to) {
      if (sessionId && loginAttempts) {
        console.log('check it first.');
      }
      console.log('set it upp...');
      window.setTimeout(function () {
        if (to) {
          if (loginAttempts <= 2) {
            authActions.authAttempt();
            window.location.replace(to + '?redirect=/auth/login');
          } else {
            console.warn('authAttempts:', loginAttempts);
          }
        }
      }, 500);
    }
  }

  handleForgot = ({username}) => {
    const {authLoginNewPasswordRequest} = this.props;

    authLoginNewPasswordRequest({username});
  };

  handleSelect = type => {
    const {authGotoNext} = this.props;

    authGotoNext(type);
  };

  verifyUser = ({username}) => {
    const {authVerifyUser} = this.props;

    if (username) {
      authVerifyUser({username});
    }
  };

  handleLogin = ({username, password, stage}) => {
    const {authLoginRequest} = this.props;

    authLoginRequest({
      username,
      password,
    });
  };

  render() {
    const {
      sessionId,
      forgotStatus,
      forgotStatusMsg,
      secondLogin,
      authStatus,
      to,
      stage,
      serviceName = null,
      mainImage = null,
      currLanguage,
      languages = allowedLanguages,
      authCloseResetPassowrdForm,
    } = this.props;
    const {showSection} = this.state;

    if (to) {
      return (
        <div>{i18n('login.progress')}</div>
      );
    }

    const loginFailure = authStatus && authStatus.loginFailed;

    if (false === true && AuthService.isAuthorized(sessionId)) {
      return <Redirect to="/my-education" />;
    }

    if (forgotStatus === 2 && showSection === 'forgot') {
      this.setState({showSection: 'login'});
    }

    // Use this to switch menu type
    const loginType = 0;

    if (loginType === 0 && secondLogin && sessionId) this.handleSelect('this');

    const onChoosedLanguage = langcode => {
      localStorage.setItem('language', langcode);
      this.forceUpdate();
    };

    return (
      <Wrapper className="login-wrapper">
        <LeftPart className="left-part">
          <>
            {Array.isArray(languages) && languages.length > 1 && (
              <LanguageSelectWrapper>
                <LanguageSelect
                  languages={languages}
                  currLanguage={currLanguage}
                  onChoosedLanguage={onChoosedLanguage}
                />
              </LanguageSelectWrapper>
            )}
            <>
              <LoginFormWrapper>
                <MainHeader>
                  {i18n('login.login')}
                  {serviceName && ` – ${serviceName}` || ''}
                </MainHeader>
                {isIE && <Warning>{i18n('UNSUPPORTED_BROWSER')}</Warning>}
                {showSection === 'login' && (
                  <LoginFormTwo
                    initialValues={{
                      username: '',
                      stage,
                      password: '',
                    }}
                    stage={stage}
                    onSubmit={this.handleLogin}
                    invalidCredentials={loginFailure}
                    handleForgotPassword={() => {
                      this.setState({showSection: 'forgot'});
                    }}
                  />
                )
                    || (showSection === 'forgot' && (
                      <ChangePasswordForm
                        forgotStatus={forgotStatus}
                        initialValues={{username: ''}}
                        onSubmit={this.handleForgot}
                        forgotStatusMsg={forgotStatusMsg}
                        handleCancel={() => {
                          this.setState({showSection: 'login'});
                          authCloseResetPassowrdForm();
                        }}
                      />
                    )
                    || showSection === 'faq' && (
                      <div>
                        <h1>FAQ</h1>
                      </div>
                    ))}
              </LoginFormWrapper>

              <Footer>
                <FooterLogo aria-label="Snapper logo">
                  <SnapperLogoDimmed
                    width="65"
                    height="65"
                    aria-hidden="true"
                  />
                </FooterLogo>
                <FooterTextWrapper>
                  <FooterMainText>
                    <a
                      target="_blank"
                      href="https://snapper.no"
                      rel="noreferrer"
                    >
                      Snapper Net Solutions
                    </a>
                  </FooterMainText>
                  <FooterSecondaryText>
                    <span css={screenreaderOnlyMinxin}>
                      {i18n('login.version') + ':'}
                    </span>
                    {' '}
                    {`${buildHash} v. ${version}`}
                  </FooterSecondaryText>
                </FooterTextWrapper>
              </Footer>
            </>
          </>

        </LeftPart>

        <RightPart className="right-part">
          <BgImage
            className="login-background"
            src={backgroundOslo1}
          />
        </RightPart>
      </Wrapper>
    );
  }
}

LoginContainer.propTypes = {
  sessionId: PropTypes.string,
  secondLogin: PropTypes.bool.isRequired,
  forgotStatus: PropTypes.number,
  forgotStatusMsg: PropTypes.string,
  authLoginRequest: PropTypes.func.isRequired,
  authGotoNext: PropTypes.func.isRequired,
  authVerifyUser: PropTypes.func.isRequired,
  authLoginNewPasswordRequest: PropTypes.func.isRequired,
  authCloseResetPassowrdForm: PropTypes.func.isRequired,
  submitErrors: PropTypes.shape({}),
  stage: PropTypes.string.isRequired,
  currLanguage: PropTypes.string.isRequired,
};

LoginContainer.defaultProps = {
  sessionId: undefined,
  forgotStatus: -1,
  forgotStatusMsg: undefined,
  submitErrors: {},
};

const mapStateToProps = state => ({
  sessionId: state.auth.sessionId,
  stage: state.auth.stage,
  loginAttempts: state.auth.loginAttempts,
  forgotStatus: state.auth.newPwdStatus,
  secondLogin: state.auth.secondLogin,
  forgotStatusMsg: state.auth.newPwd,
  authStatus: getAuthStatus(state),
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {...authActions},
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);
