import styled from '@emotion/styled';

export const SearchResult = styled.div`
  margin: 0;
  padding: 0;
  max-height: 400px;

  z-index: 100;
  overflow-y: auto;
  background-color: white;
  h4{
    margin-top: 0;
    font-weight: normal;
    color: #6A6A6A;
    font-size: 0.9em;
  }
`;

export const CompanyIco = styled.div`
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 600;
  color: #aaa;
  justify-items: center;
  justify-content: center;
  display: flex;
  background-color: #dedede;
  margin-right: 10px;
  border-radius: 8px;
`;

export const PersonIco = styled.div`
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 600;
  color: #aaa;
  justify-items: center;
  justify-content: center;
  display: flex;
  background-color: #dedede;
  margin-right: 10px;
  border-radius: 40px;
`;

export const Section = styled.div`
  margin-bottom: 40px;
`;

export const CompanyName = styled.div`
  :hover {
    text-decoration: underline;
  }
`;

export const Item = styled.div`
  padding: 5px;
  margin: 10px 0;
  margin-right: 20px;
  align-items: center;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  border: 1px solid #fff;
  border-radius: 4px;
  :hover {
    background-color: #ECF2FC;
    border: 1px solid #efefef;
  }
`;

export const Name = styled.div`
  display: flex;
  flex-direction: column;
  div {
    &: hover {
      text-decoration: underline;
    }
  }
`;

export const Company = styled.div`
  font-size: 0.9em;
  color: #6A6A6A;
  &: hover {
    text-decoration: underline;
  }
`;
