import React from 'react';
import styled from '@emotion/styled';
import {css, jsx} from '@emotion/react';
import {ActionButton} from '@components/buttons/actionButton';
import moment from 'moment';
import countdown from 'countdown';

import {TopDescription,  TopBarWrapper, TopBarActionButtonsWrapper, deleteStyle} from './cv-styles';
import {i18n} from '@src/i18n';

const MissingContentCont = styled.div`
  color: #494949;
`;

const EditButtonCont = styled.span`
  padding: 4px;
  float: right;
`;

const Title = styled.h2`
  display: inline-block;
  margin-top: 0;
  font-weight: 500;
  ${props => props.centerTitle ? 'left: 50%; position:relative;' : ''}
`;



const Container = styled.div`
  width: 100%;
  height: ${({mode}) => mode === 'edit' || mode === 'add' ? 'fit-content' : '100%'};
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;
const Seperator = styled.div`
  flex: 0 0 auto;
  background: #eaebed;
  width: 100%;
  height: 1px;
  margin-top: ${props => props.marginTop}px;
  margin-bottom: ${props => props.marginBottom}px;
`;


/**
 * A container for displaying its child elements with a title and a border beneath the title.
 *
 * A action-button can be provided and will be placed at the top right of the container
 */
export const InfoCollection = ({
  title,
  actionButton = null,
  children,
  style,
  titleStyle,
  mode,
  centerTitle,
  missingContentComponent,
}) => (
  <Container
    style={style}
    mode={mode}
  >
    {title && (
      <Title
        centerTitle={centerTitle}
        style={titleStyle}
      >
        {title}
      </Title>
    )}
    {actionButton && <EditButtonCont>{actionButton}</EditButtonCont>}
    {title && <Seperator />}
    {children
          || missingContentComponent
            && <MissingContentCont>{missingContentComponent}</MissingContentCont>}
  </Container>
);


export const TopBar = ({
  manageMode,
  isFetching,
  onClickEditSave,
  title,
  onClickRemove,
}) =>  (
  <TopBarWrapper>
    <TopDescription theme={manageMode === 'view' ? 'dark' : 'light'}>
      {title}
    </TopDescription>
    <TopBarActionButtonsWrapper>
      {!isFetching && (
        <>
          {manageMode === 'view' && (
            <>
              {onClickRemove && (
                <ActionButton
                  onClick={onClickRemove}
                  variant="danger"
                  style={deleteStyle}
                >
                  {i18n('globals.delete')}
                </ActionButton>
              )}
              {onClickEditSave && (
                <ActionButton
                  onClick={onClickEditSave}
                  variant="primary"
                >
                  {i18n('globals.edit')}
                </ActionButton>
              )}
            </>
          ) || (
            <>
              <ActionButton
                onClick={onClickEditSave}
                variant="primary"
              >
                {i18n('globals.save')}
              </ActionButton>
            </>
          )}
        </>
      )}
    </TopBarActionButtonsWrapper>
  </TopBarWrapper>
);

const PointSeperatorStyle = styled.span`
  &::after {
    content: '•';
  }
  margin-left: 6px;
  margin-right: 6px;
  font-size: 0.7em;
`;


export const PointSeparator = () => <PointSeperatorStyle aria-hidden="true" />; ;

export const FormattedDate = ({fromDateStr, toDateStr, yearOnly, wrapperStyle}) => {
  const ongoing = !toDateStr;

  if (!fromDateStr && !toDateStr) {
    return null;
  }

  const strToMoment = str => {
    const m1 = moment(str, 'DD.MM.YYYY');

    if(m1.isValid()) {
      return m1;
    }

    const m2 = moment(str, 'DD.MM.YYYY');

    if(m2.isValid()) {
      return m2;
    }
  };

  const dateFrom = strToMoment(fromDateStr);

  const dateTo = ongoing && moment()
    .minute(0)
    .hour(0) ||  strToMoment(toDateStr);

  const getFormattedFrom = (dateFrom, yearOnly) => {
    if(yearOnly) {
      return dateFrom.format('YYYY');
    }

    return dateFrom.format('MMM YYYY');
  };



  const getFormattedTo = (dateTo, yearOnly) => {
    if(ongoing) {
      return ' ' + i18n('globals.now', {lowerCase: true});
    }
    if(yearOnly) {
      return dateTo.format('YYYY');
    }

    return dateTo.format('MMM YYYY');
  };

  const getDiffString = (dateFrom, dateTo) => {
    // to make comparision correct, remove the hour and minute part
    const diff = countdown(dateFrom.hour(0).minute(0)
      .toDate(), dateTo.hour(0).minute(0)
      .toDate());

    let diffFormatted = '';

    if(diff.years > 1) {
      diffFormatted += `${diff.years} ${i18n('date-and-time.years-abbr', {lowerCase: true})} `;
    }

    if(diff.years === 1) {
      diffFormatted += `1 ${i18n('date-and-time.year-abbr', {lowerCase: true})} `;
    }

    if(diff.months > 1) {
      diffFormatted += `${diff.months} ${i18n('date-and-time.months-abbr', {lowerCase: true})} `;
    }

    if(diff.months === 1) {
      diffFormatted += `1 ${i18n('date-and-time.month-abbr', {lowerCase: true})} `;
    }
    if(diff.months === 0 && diff.years === 0) {
      if(diff.days > 1) {
        diffFormatted += `${diff.days} ${i18n('date-and-time.days', {lowerCase: true})} `;
      }
      if(diff.days === 1) {
        diffFormatted += `1 ${i18n('date-and-time.day-abbr', {lowerCase: true})} `;
      }
    }


    return diffFormatted;
  };

  const diffFormatted = getDiffString(dateFrom, dateTo);
  const fromFormatted = getFormattedFrom(dateFrom, yearOnly);
  const toFormatted = getFormattedTo(dateTo, yearOnly);

  return (
    <span
      css={css`
        display: flex;
        align-items: center;
        white-space: nowrap;

    ${wrapperStyle || ''};
    `}
    >
      {fromFormatted}
      {' '}
      -
      {' '}
      {toFormatted}

      {diffFormatted
      && (
        <>
          <PointSeparator />
          {diffFormatted}
        </>
      )}
    </span>
  );
};

