import {backendUrl} from '@config';
import axios from 'axios';
import {takeLatest, put, call, select} from 'redux-saga/effects';
import {
  COMPETENCES_TOGGLE,
  competencesToggleRequest,
  competencesToggleSuccess,
  competencesToggleFailure,
  competenceSave,
  competenceSaveRequest,
  competenceRemoveFailure,
  competenceRemoveSuccess,
  competenceRemove,
  competenceRemoveRequest,
} from '@actions/competences.actions';

import {profileFetchPerson, profileUpdateOneCompetences} from '@actions/profile.actions';
import {notificationsAdd} from '@actions/notifications.actions';

import {
  employeesFetchChecklists,
  employeesFetchSelectedPerson,
} from '@actions/employees.actions';

import {
  getOrganisationId, getProfile,
  getProfileUserName,
} from '@selectors/profile.selectors';
import {getPropertiesForCurrLangAndTrack} from '@selectors/config.selectors';
import {setMapIsVerified} from '@actions/map.actions';
import {getSelectedPersonUsername} from '../selectors/employees.selectors';
import {getMapVerification} from '@selectors/map.selectors';
import {i18n} from '@src/i18n';

const CompetenceAxios = axios.create({
  headers: {'X-Grape-Lang': localStorage.getItem('language')},
  withCredentials: true,
});

function* toggleCompetence(payload) {
  yield put(competencesToggleRequest());
  const organisationId = yield select(getOrganisationId);

  try {
    const {
      cid,
      pid,
      size,
      disableNotifications,
      disableStatusUpdateCheck,
      noRefetchCompetencesAfterPosting,
    } = payload.payload;

    let resp = null;

    if (size === 'dashboard') {
      /*
      THIS IS EMPLOYEE, SO IT IS MANAGER THAT DOES THIS.
       */
      const action_name = `checklist_item_manager_${cid}`;

      resp = yield call(CompetenceAxios.request, {
        method: 'POST',
        url: `${backendUrl}/courses/setchecklistitem`,
        params: {
          name: action_name,
          value: true,
          person_id: pid,
        },
        withCredentials: true,
      });

      if (window.location.pathname.includes('/dashboard')) {
        yield put(employeesFetchChecklists());
      } else {
        const userName = yield select(getSelectedPersonUsername);

        yield put(employeesFetchSelectedPerson({userName}));
      }

      if (!disableNotifications) {
        yield put(notificationsAdd({
          notification: {
            text: 'Sjekkliste er oppdatert.',
            color: 'green',
          },
        }));
      }
    } else {
      const userName = yield select(getProfileUserName);

      resp = yield call(CompetenceAxios.request, {
        method: 'POST',
        url: `${backendUrl}/api/personcompetences`,
        params: {
          competence_id: cid,
          user_name: userName,
          passed: 100,
          action: 'on',
        },
        withCredentials: true,
      });

      if (!disableNotifications) {
        yield put(notificationsAdd({
          notification: {
            text: 'Sjekkliste er oppdatert.',
            color: 'green',
          },
        }));
      }

      if (!noRefetchCompetencesAfterPosting) {
        yield put(profileUpdateOneCompetences({disableStatusUpdateCheck}));
      }
    }

    // check if this action did verify the map
    const configForCurrLangAndTrack = yield select(getPropertiesForCurrLangAndTrack);
    const mapVerification = yield select(getMapVerification);

    if (mapVerification) {
      const responseCompetence
        = resp.data.personcompetences
        && Array.isArray(resp.data.personcompetences)
        && resp.data.personcompetences.length > 0
        && resp.data.personcompetences[0];

      const verifiedMap
        = cid === responseCompetence.competence_id
        && !responseCompetence.cancelled;

      if (verifiedMap) {
        yield put(setMapIsVerified());
      }
    }

    yield put(competencesToggleSuccess({}));
    /*
    yield put(
      fetchCompetencesChildren({
        courseId: 30,
        personId: profileId,
        orgId: organisationId,
        root: 'show-role',
      })
    );
    */
  } catch (error) {
    console.error(error);
    yield put(competencesToggleFailure({error}));
  }
}


function* removeCompetence(payload) {
  const {data} = payload.payload;

  try {
    yield put(competenceRemoveRequest());
    const resp = yield call(CompetenceAxios.request, {
      method: 'POST',
      url: `${backendUrl}/persons/remove_competence/${data.orgId}/${data.person_id}/${data.course_id}`,
      params: {json: 1},
      withCredentials: true,
    });

    yield put(competenceRemoveSuccess());
    yield put(notificationsAdd({
      notification: {
        text: i18n('person.competence-removed'),
        color: 'green',
      },
    }));

    const {data: profile} = yield select(getProfile);

    if (data.userName === profile.user_name) {
      yield put(profileFetchPerson());
    }

    yield put(employeesFetchSelectedPerson({userName: data.userName}));
  } catch (error) {
    console.error(error);
    yield put(competenceRemoveFailure({error}));
  }
}
const FILE_UPLOAD_AXIOS_CONFIG = {
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'multipart/form-data',
  },
};


function* saveCompetence(payload) {
  const {data, files} = payload.payload;

  try {
    yield put(competenceSaveRequest());

    const formData = new FormData();

    if (files) {
      if (files.length === 1) {
        files.forEach(file =>
          formData.append('certificate', file));
      }else {
        files.forEach(file =>
          formData.append('certificates', file));
      }
    }

    formData.append('json', 1);
    formData.append('person_id', data.person_id);
    formData.append('course_id', data.course_id);
    formData.append('comment', data.comment);
    formData.append('valid_until', data.valid_until);
    formData.append('verifieddate', data.verifieddate);
    formData.append('registered_manually', 'on');

    /*

    json: 1,
      person_id: data.person_id,
      course_id: data.course_id,
      comment: data.comment,
      valid_until: data.valid_until,
      verifieddate: data.verifieddate,
      registered_manually: 'on',

    const {data: responseData} = yield api.post(`files/savemanagefiles/${type}/${id}`, formData, FILE_UPLOAD_AXIOS_CONFIG);
*/
    const instance = axios.create({baseURL: backendUrl});

    instance.defaults.withCredentials = true;

    const {data: responseData} = yield instance.post(
      `/persons/updatecompetence/${data.phceId}/${data.orgId}`,
      formData,
      {...FILE_UPLOAD_AXIOS_CONFIG},
    );

    yield put(notificationsAdd({
      notification: {
        text: i18n('person.user-updated-success'),
        color: 'green',
      },
    }));

    const {data: profile} = yield select(getProfile);

    if (data.userName === profile.user_name) {
      yield put(profileFetchPerson());
    }

    yield put(employeesFetchSelectedPerson({userName: data.userName}));
  } catch (error) {
    console.error(error);
    yield put(competenceSaveFailure({error}));
  }
}

const exportObj = [
  takeLatest(COMPETENCES_TOGGLE, toggleCompetence),
  takeLatest(competenceSave().type, saveCompetence),
  takeLatest(competenceRemove().type, removeCompetence),
];

export default exportObj;
