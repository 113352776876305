import React from 'react';
import PropTypes from 'prop-types';
import {css, jsx} from '@emotion/react';
import {Field, reduxForm} from 'redux-form';

import {FormInput} from './FormInput';
import {
  Button,
  box,
  stack,
  mt,
  ButtonLink,
  ActionButtonsWrapper,
  Label,
} from './styles';
import {i18n} from '@src/i18n';

const ChangePasswordForm = ({handleSubmit, handleCancel, forgotStatus, forgotStatusMsg}) => (
  <form
    onSubmit={handleSubmit}
    css={[
      box,
      stack,
      css`
        width: 100%;
      `,
    ]}
  >
    <div
      css={css`
        width: 100%;
      `}
    >
      <Label for="username-forgot-pw-field">{i18n('login.username')}</Label>
      <Field
        autoComplete="username"
        component={FormInput}
        id="username-field"
        name="username"
        type="text"
      />
      {forgotStatus === 1 && (
        <div
          role="alert"
          css={css`
            color: #ee0c00;
            margin-bottom: 1em;
          `}
        >
          {i18n('login.no-user')}
          <br />
          {/* i18n('NO_USER_FOUND_HELP_EMAIL') */}
        </div>
      )
        || forgotStatus !== -1 && <div>{forgotStatusMsg}</div>}
    </div>

    <ActionButtonsWrapper>
      <Button
        sizeVariant="fullwidth"
        type="submit"
      >
        {i18n('login.recive-new')}
      </Button>
      <ButtonLink
        css={[
          mt,
          css`
            width: 100%;
          `,
        ]}
        type="button"
        onClick={handleCancel}
      >
        {i18n('globals.cancel')}
      </ButtonLink>
    </ActionButtonsWrapper>
  </form>
);

ChangePasswordForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  forgotStatus: PropTypes.number.isRequired,
};

export default reduxForm({form: 'changepwd'})(ChangePasswordForm);
