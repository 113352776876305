import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import * as Sentry from '@sentry/react';
import {Integrations} from '@sentry/tracing';
import * as serviceWorker from './serviceWorker';
import {configureStore} from '@store';
import {version} from '@config';

import '@fortawesome/fontawesome-free/scss/fontawesome.scss';
import '@fortawesome/fontawesome-free/scss/solid.scss';
import '@fortawesome/fontawesome-free/scss/regular.scss';
import './index.scss';
import {ThemeProvider} from '@emotion/react';
import {snapperTheme, localThemes} from './common/styles/themes/theme';


import App from './app';

const store = configureStore();

let localTheme = {};

if (process.env.NODE_ENV && process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://86b43514fafa44f1830181ed78f2d228@o1035034.ingest.sentry.io/6003562',
    integrations: [new Integrations.BrowserTracing()],
    release: `waymaker@${version}`,
    tracesSampleRate: 0.1,
  });
}

if (localStorage.getItem('theme') && Object.keys(localThemes).includes(localStorage.getItem('theme'))) {
  localTheme = localThemes[localStorage.getItem('theme')] || {};
  // test theme: localTheme = localThemes["red"]
  // test default theme: localTheme = localThemes[null]
}

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={snapperTheme}>
      <ThemeProvider theme={localTheme}>
        <App />
      </ThemeProvider>
    </ThemeProvider>
  </Provider>,
  document.querySelector('#root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
