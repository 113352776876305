import {
  put,
  takeLatest,
  select,
} from 'redux-saga/effects';
import axios from 'axios';
import {backendUrl, backendUrlV2} from '@config';

import retry from '@utils/sagas.utils';

import * as mapCoursesActions from '@actions/map.actions';

import {
  getCurrTrack,
  getPropertiesForCurrLangAndTrackBadge,
} from '@selectors/config.selectors';

import {getIsAllMapDotsCompleted} from '@selectors/map.selectors';
import {selectMapCourse} from '@actions/map.actions';

export function* fetchMapCourses(action) {
  const {id} = action.payload;
  const currTrack = id ? id : yield select(getCurrTrack);

  yield put(mapCoursesActions.fetchMapCoursesRequest());
  try {
    const mapCourses = yield retry(() =>
      axios
        .request({
          method: 'GET',
          url: `${backendUrlV2}/tracks/${currTrack}/view`,
          params: {id: currTrack},
          withCredentials: true,
        })
        .then(res => res.data));

    yield put(mapCoursesActions.fetchMapCoursesSucceeded({data: mapCourses}));
    const isAllDone = yield select(getIsAllMapDotsCompleted);
    const badgeInfo = yield select(getPropertiesForCurrLangAndTrackBadge);

    if (isAllDone && badgeInfo) {
      yield put(selectMapCourse({id: 'badge'}));
    }
  } catch (error) {
    console.error(error);
  }
}

export function* fetchTrack(action) {
  console.log('fetchTrack', action);
  const {id} = action.payload;

  yield put(mapCoursesActions.fetchTrackRequest());
  try {
    const track = yield retry(() =>
      axios.request({
        method: 'GET',
        url: `${backendUrlV2}/competences/`,
        params: {
          fields: 'track_image,title,children_ids,description',
          types: 'onboarding',
          users_organisations_only: 1,
        },
        withCredentials: true,
      }).then(res => res.data));

    const tracks = yield retry(() =>
      axios.request({
        method: 'GET',
        url: `${backendUrlV2}/tracks/${track[0]?.id}/children`,
        params: {
          fields: 'id,track_image,title,children_ids,description',
          users_organisations_only: 1,
        },
        withCredentials: true,
      }).then(res => res.data));

    yield put(mapCoursesActions.fetchTrackSuccess({
      track: track[0],
      tracks,
    }));
  } catch (error) {
    console.error(error);
    yield put(mapCoursesActions.fetchTrackFailure({error}));
  }
}


export function* fetchMapCompetence(action) {
  const {id} = action.payload;

  console.log('start up....', id);
  yield put(mapCoursesActions.fetchMapCompetenceRequest({id}));
  try {
    const competence = yield retry(() =>
      axios
        .request({
          method: 'GET',
          url: `${backendUrlV2}/competences/${id}`,
          params: {fields: 'title,files,children'},
          withCredentials: true,
        })
        .then(res => res.data));

    console.log('is done', competence);
    yield put(mapCoursesActions.fetchMapCompetenceSucceeded({data: competence}));
  } catch (error) {
    console.error(error);
  }
}

export function* fetchMapVerificationCourse(action) {
  yield put(mapCoursesActions.fetchMapVerificationCompetenceRequest());
  const {cid} = action.payload;

  try {
    const course = yield retry(() =>
      axios
        .request({
          method: 'GET',
          url: `${backendUrl}/api/competences/${cid}?fields=short_description,files`,
          withCredentials: true,
        })
        .then(res => res.data));

    yield put(mapCoursesActions.fetchMapVerificationCompetenceSucceeded({data: course.competences[0]}));
  } catch (error) {
    console.error(error);
  }
}

const exportObj = [
  takeLatest(mapCoursesActions.FETCH_MAP_COURSES, fetchMapCourses),
  takeLatest(mapCoursesActions.FETCH_TRACK, fetchTrack),
  takeLatest(mapCoursesActions.FETCH_MAP_COMPETENCE, fetchMapCompetence),
  takeLatest(
    mapCoursesActions.FETCH_MAP_VERIFICATION_COMPETENCE,
    fetchMapVerificationCourse,
  ),
];

export default exportObj;
