import {css} from '@emotion/react';
import {mix, darken, linearGradient} from 'polished';
import styled from '@emotion/styled';

export const Divider = styled('div')(
  {width: '100%'},
  props => ({
    backgroundColor: darken(props.darkenWith || 0, props.color || '#555'),
    height: props.height || 1,
  }),
);

export const linearGradientWithAdjustableColorStop = (
  color = '#FF6C00',
  threshold = 0.4,
  thresholdOption = 0,
  darkenWith = 0.2,
) => {
  // Linear Interpolation of color
  const colorMix = mix(threshold, color, darken(darkenWith, color));

  // Array for polished libs' linearGradient colorStop value
  let colorStopsArray;

  /**
   * * Option 0 (default): Gradient colorStops are at 0% and 100%
   * * Option 1: Stop gradient at threshold
   * * Option 2: Begin gradient at threshold
   */

  switch (thresholdOption) {
  case 1:
    colorStopsArray = [`${color} 0%`, `${colorMix} 100%`];
    break;
  case 2:
    colorStopsArray = [`${colorMix} 0%`, `${darken(darkenWith, color)} 100%`];
    break;
  default:
    colorStopsArray = [`${color} 0%`, `${darken(darkenWith, color)} 100%`];
    break;
  }

  return {
    ...linearGradient({
      colorStops: colorStopsArray,
      toDirection: 'to bottom',
      fallback: color,
    }),
  };
};

export const textScale = ['0.75rem', '0.975rem', '1rem', '1.25rem', '2rem'];

export const space = [0, 4, 8, 12, 16, 20, 24, 32, 40];

const sizes = {
  small: 'min-width: 320px',
  medium: 'min-width: 751px',
  large: 'min-width: 1051px',
};

export const mq = {
  small: `@media(${sizes.small})`,
  medium: `@media(${sizes.medium})`,
  large: `@media(${sizes.large})`,
};

const sizesMax = {
  small: 'max-width: 320px',
  medium: 'max-width: 751px',
  large: 'max-width: 1051px',
};

export const mqMax = {
  small: `@media(${sizesMax.small})`,
  medium: `@media(${sizesMax.medium})`,
  large: `@media(${sizesMax.large})`,
};

/**
 * Combine media queries using sizes
 * @param  {...any} args The sizes to combine, in the wanted order
 */
export const or = (...args) =>
  `${`@media${args.map(size => ` ${size.slice(6)},`).join('')}`.slice(
    0,
    -1,
  )} `;

/**
 * * Helpers
 */

export const cover = css({
  /**
   * * -moz-available
   * This doesn't work for 'height' in Firefox
   *
   * * -webkit-fill-available
   * This does work for 'height' in Chrome or Safari,
   * but Safari has a weird re-draw issue
   * (open and close dev-tools and you'll see).
   * */
  minHeight: ['-moz-available', '-webkit-fill-available'],
  height: '100%',
});

export const measureCap = css({
  /**
   * The numbers of letters on a line can contribute to reader's fatigue,
   * so keep the line length to 45–75 characters per line.
   */
  maxWidth: '60ch',
});
