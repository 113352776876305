import * as profileActions from '@actions/profile.actions';
import * as T from '../../store/types/load.types';

const initialState = {
  person: {
    isFetching: false,
    data: null,
    error: null,
    updated: null,
    storeManager: false,
    orgId: null,
    organisations: null,
  },
  helptexts: null,
  tab: 'role',
  section: 'competences',
  competences: {
    isFetching: false,
    isSubFetching: false,
    data: null,
    error: null,
    updated: null,
  },
  menu: {
    status: T.LoadStatuses.NOT_LOADED,
    data: null,
  },
  expiring: {
    isFetching: false,
    data: null,
    updated: null,
  },
  tenets: {
    status: T.LoadStatuses.NOT_LOADED,
    data: null,
  },
  passed: {
    isFetching: false,
    data: null,
    error: null,
    updated: null,
  },
  passed_full: {
    isFetching: false,
    data: null,
    error: null,
    updated: null,
  },
  events: {
    isFetching: false,
    data: null,
    tracks: null,
    error: null,
    updated: null,
  },
  summary: {
    isFetching: false,
    data: null,
    error: null,
    updated: null,
  },
  show_role: {
    isFetching: false,
    data: null,
    error: null,
    updated: null,
  },
  roles: {
    isFetching: false,
    data: null,
    error: null,
    updated: null,
  },
  report:{
    data: null,
    error: null,
    updated: null,
    isFetching: false,
  },
  cvs:{
    isFetching: false,
    data: null,
    error: null,
    updated: null,
  },
  cvs_full:{
    isFetching: false,
    data: null,
    error: null,
    updated: null,
  },
  specialroles: {
    data: null,
    superuser: false,
  },
  isFetching: false,
  updated: null,
};

const profile = (state = initialState, action) => {
  switch (action.type) {
  case 'RESET_STORE':
    return initialState;
  case profileActions.PROFILE_FETCH_PERSON_REQUEST:
    return {
      ...state,
      person: {
        ...state.person,
        isFetching: true,
        data: null,
        updated: null,
      },
    };
  case profileActions.PROFILE_FETCH_PERSON_FULL:
    return {
      ...state,
      isFetching: true,
    };
  case profileActions.PROFILE_EDIT_PERSON_REQUEST:
    return {
      ...state,
      isFetching: true,
    };
  case profileActions.PROFILE_EDIT_PERSON_SUCCESS:
    return {
      ...state,
      isFetching: false,
    };
  case profileActions.PROFILE_SET_MANAGER:
    return {
      ...state,
      person: {
        ...state.person,
        storeManager: true,
      },
    };
  case profileActions.PROFILE_SET_SPECIALROLES:
    return {
      ...state,
      specialroles: {
        ...state.specialroles,
        data: action.payload.data,
        superuser: action.payload.superuser,
        driftsjef: action.payload.driftsjef,
      },
    };
  case profileActions.SWITCH_TAB:
    return {
      ...state,
      tab: action.payload,
    };
  case profileActions.SWITCH_SECTION:
    return {
      ...state,
      section: action.payload,
    };
  case profileActions.PROFILE_FETCH_PERSON_FULL_SUCCESS:
    return {
      ...state,
      isFetching: false,
      updated: new Date(),
    };
  case profileActions.PROFILE_FETCH_EVENTS_SUCCESS:
    return {
      ...state,
      events: {
        isFetching: false,
        data: action.payload?.events?.personevents ? action.payload.events.personevents.filter(pe => pe.competence_type !== 28) : state.events.data,
        tracks: action.payload?.events?.personevents ? action.payload.events.personevents.filter(pe => pe.competence_type === 28) : state.events.tracks,
      },
    };
  case profileActions.PROFILE_SIGNED_ON_SINGLE_EVENT:
    return {
      ...state,
      events:{
        ...state.events,
        data: [...state.events.data || [], action.payload.event],
      }, 
    };
  case profileActions.PROFILE_FETCH_PERSON_SUCCESS:
    return {
      ...state,
      person: {
        ...state.person,
        isFetching: false,
        data: action.payload.person,
        competencelevel: action.payload.competencelevel,
        updated: new Date(),
      },
    };
  case profileActions.PROFILE_FETCH_PERSON_FAILURE:
    return {
      ...state,
      person: {
        ...state.person,
        isFetching: false,
        data: null,
        error: action.payload.error,
        updated: null,
      },
    };
  case profileActions.PROFILE_SET_LAST_MESSAGE_TIMESTAMP:
    return {
      ...state,
      person: {
        ...state.person,
        data: {
          ...state.person.data,
          data: {
            ...state.person.data.data,
            lastMessage: action.payload.last_message,
          },
        },
      },
    };
  case profileActions.PROFILE_FETCH_HELPTEXTS_SUCCESS:
    return {
      ...state,
      helptexts: action.payload.data,
    };
  case profileActions.PROFILE_FETCH_ALLORGS_REQUEST:
    return {
      ...state,
      person: {
        ...state.person,
        tenets: {
          status: T.LoadStatuses.IS_LOADING,
          data: null,
        },
      },
    };
  case profileActions.PROFILE_FETCH_ALLORGS_FAILURE:
    return {
      ...state,
      person: {
        ...state.person,
        tenets: {
          status: T.LoadStatuses.FAILED,
          data: null,
        },
      },
    };
  case profileActions.PROFILE_FETCH_ALLORGS_SUCCESS:
    return {
      ...state,
      person: {
        ...state.person,
        tenets: {
          status: T.LoadStatuses.LOADED,
          data: action.payload.data.length !== undefined ? action.payload.data : null,
        },
      },
    };
  case profileActions.PROFILE_FETCH_ROLE_REQUEST:
    return {
      ...state,
      show_role: {
        ...state.show_role,
        isFetching: true,
        data: null,
        updated: null,
      },
    };
  case profileActions.PROFILE_FETCH_ROLE_SUCCESS:
    return {
      ...state,
      show_role: {
        ...state.show_role,
        isFetching: false,
        data: action.payload.role,
        updated: new Date(),
      },
    };
  case profileActions.PROFILE_FETCH_ROLE_FAILURE:
    return {
      ...state,
      show_role: {
        ...state.show_role,
        isFetching: false,
        data: null,
        updated: null,
      },
    };

  case profileActions.PROFILE_FETCH_CV_REQUEST:
    return {
      ...state,
      cvs_full: {
        ...state.cvs_full,
        isFetching: true,
        data: null,
        updated: null,
      },
    };
  case profileActions.PROFILE_FETCH_CV_SUCCESS:
    const {cv: fetchedCv} = action.payload;

    return {
      ...state,
      cvs_full: {
        ...state.cvs_full,
        isFetching: false,
        data: {
          ...state.cvs_full.data,
          [fetchedCv.id]: fetchedCv,
        },
        updated: new Date(),
      },
    };
  case profileActions.PROFILE_FETCH_CV_FAILURE:
    return {
      ...state,
      cvs_full: {
        ...state.cvs_full,
        isFetching: false,
        data: null,
        error: true,
        updated: null,
      },
    };


  case profileActions.PROFILE_FETCH_CVS_REQUEST:
    return {
      ...state,
      cvs: {
        ...state.cvs,
        isFetching: true,
        updated: null,
      },
    };
  case profileActions.PROFILE_FETCH_CVS_SUCCESS:

    return {
      ...state,
      cvs: {
        ...state.cvs,
        isFetching: false,
        data: Object.fromEntries(action.payload.cvs.map(cv => [cv.id, cv])),
        updated: new Date(),
      },
    };
  case profileActions.PROFILE_FETCH_CVS_FAILURE:
    return {
      ...state,
      cvs: {
        ...state.cvs,
        isFetching: false,
        error: true,
        updated: null,
      },
    };

  case profileActions.PROFILE_EDIT_CV_REQUEST:
    return {
      ...state,
      cvs_full: {
        ...state.cvs_full,
        isFetching: true,
        data: null,
        updated: null,
      },
    };
  case profileActions.PROFILE_EDIT_CV_SUCCESS:
    const {cv: fetchedCvFromEdit} = action.payload;

    return {
      ...state,
      cvs_full: {
        ...state.cvs_full,
        data: {
          ...state.cvs_full.data,
          [fetchedCvFromEdit.id]: fetchedCvFromEdit,
        },
        isFetching: false,
        updated: new Date(),
      },
    };
  case profileActions.PROFILE_EDIT_CV_FAILURE:
    return {
      ...state,
      cvs_full: {
        ...state.cvs_full,
        isFetching: false,
        data: null,
        error: true,
        updated: null,
      },
    };

  case profileActions.PROFILE_REMOVE_CV_REQUEST:
    return {
      ...state,
      cvs_full: {
        ...state.cvs_full,
        isFetching: true,
        updated: null,
      },
    };
  case profileActions.PROFILE_REMOVE_CV_SUCCESS:
    const {removedId} = action.payload;
    const removedCvExisting = {...state.cvs.data};
    const removedCvExistingFull = {...state.cvs_full.data};

    delete removedCvExistingFull[removedId];
    delete removedCvExisting[removedId];

    return {
      ...state,
      cvs_full: {
        ...state.cvs_full,
        data: removedCvExisting,
        isFetching: false,
        updated: new Date(),
      },
      cvs:{
        ...state.cvs,
        data: removedCvExisting,
      },
    };
  case profileActions.PROFILE_REMOVE_CV_FAILURE:
    return {
      ...state,
      cvs_full: {
        ...state.cvs_full,
        isFetching: false,
        error: true,
        updated: null,
      },
    };

  case profileActions.PROFILE_FETCH_REPORT_REQUEST:
    return {
      ...state,
      report: {
        ...state.report,
        isFetching: true,
        data: null,
        updated: null,
      },
    };
  case profileActions.PROFILE_FETCH_REPORT_SUCCESS:
    return {
      ...state,
      report: {
        ...state.report,
        isFetching: false,
        data: action.payload.data,
        updated: new Date(),
      },
    };
  case profileActions.PROFILE_FETCH_REPORT_FAILURE:
    return {
      ...state,
      report: {
        ...state.report,
        isFetching: false,
        data: null,
        error: true,
        updated: null,
      },
    };
  case profileActions.PROFILE_FETCH_ROLES_REQUEST:
    return {
      ...state,
      roles: {
        ...state.roles,
        isFetching: true,
        data: null,
        updated: null,
      },
    };
  case profileActions.PROFILE_FETCH_ROLES_SUCCESS:
    return {
      ...state,
      roles: {
        ...state.roles,
        isFetching: false,
        data: action.payload.roles,
        updated: new Date(),
      },
    };
  case profileActions.PROFILE_FETCH_ROLES_FAILURE:
    return {
      ...state,
      roles: {
        ...state.roles,
        isFetching: false,
        data: null,
        updated: null,
      },
    };
  case profileActions.PROFILE_FETCH_SUMMARY_REQUEST:
    return {
      ...state,
      summary: {
        ...state.summary,
        isFetching: true,
        data: null,
        updated: null,
      },
    };
  case profileActions.PROFILE_FETCH_EXPIRING_REQUEST:
    return {
      ...state,
      expiring: {
        isFetching: true,
        data: null,
        updated: null,
      },
    };
  case profileActions.PROFILE_FETCH_EXPIRING_SUCCESS:
    return {
      ...state,
      expiring: {
        isFetching: false,
        data: action.payload.data,
        updated: new Date(),
      },
    };
  case profileActions.PROFILE_FETCH_SUMMARY_SUCCESS:
    return {
      ...state,
      summary: {
        ...state.summary,
        isFetching: false,
        data: action.payload.summary,
        updated: new Date(),
      },
    };
  case profileActions.PROFILE_FETCH_SUMMARY_FAILURE:
    return {
      ...state,
      summary: {
        ...state.summary,
        isFetching: false,
        data: null,
        updated: null,
      },
    };
  case profileActions.PROFILE_FETCH_PASSED_COMPETENCES_REQUEST:
    return {
      ...state,
      passed: {
        ...state.passed,
        isFetching: true,
        data: null,
        updated: null,
      },
    };
  case profileActions.PROFILE_FETCH_PASSED_COMPETENCES_SUCCESS:
    return {
      ...state,
      passed: {
        isFetching: false,
        data: action.payload.competences,
        updated: null,
      },
    };
  case profileActions.PROFILE_FETCH_PASSED_COMPETENCES_FAILURE:
    return {
      ...state,
      passed: {
        isFetching: null,
        data: null,
        updated: null,
        error: true,
      },
    };

  case profileActions.PROFILE_FETCH_PASSED_COMPETENCES_FULL_REQUEST:
    return {
      ...state,
      passed_full: {
        ...state.passed,
        isFetching: true,
        data: null,
        updated: null,
      },
    };
  case profileActions.PROFILE_FETCH_PASSED_COMPETENCES_FULL_SUCCESS:
    return {
      ...state,
      passed_full: {
        isFetching: false,
        data: action.payload.competences,
        updated: null,
      },
    };
  case profileActions.PROFILE_FETCH_PASSED_COMPETENCES_FULL_FAILURE:
    return {
      ...state,
      passed_full: {
        isFetching: null,
        data: null,
        updated: null,
        error: true,
      },
    };
  case profileActions.PROFILE_FETCH_COMPETENCES_REQUEST:
    return {
      ...state,
      competences: {
        ...state.competences,
        isFetching: true,
        data: null,
        updated: null,
      },
    };
  case profileActions.PROFILE_FETCH_COMPETENCES_CHILDREN_REQUEST:
    return {
      ...state,
      competences: {
        ...state.competences,
        isSubFetching: true,
      },
    };
  case profileActions.PROFILE_FETCH_SHOWROLE_CHILDREN_REQUEST:
    return {
      ...state,
      competences: {
        ...state.competences,
        isSubFetching: true,
      },
    };
  case profileActions.PROFILE_FETCH_SHOWROLE_CHILDREN_SUCCESS:
    return {
      ...state,
      competences: {
        ...state.competences,
        isSubFetching: false,
      },
      show_role: {
        ...state.show_role,
        isSubFetching: false,
        data: action.payload.competences,
      },
    };
  case profileActions.PROFILE_FETCH_COMPETENCES_CHILDREN_FAILURE:
    return {
      ...state,
      competences: {
        ...state.competences,
        isSubFetching: false,
      },
    };
  case profileActions.PROFILE_FETCH_COMPETENCES_CHILDREN_SUCCESS:
    return {
      ...state,
      competences: {
        ...state.competences,
        isSubFetching: false,
        data: action.payload.competences,
        updated: new Date(),
      },
    };
  case profileActions.PROFILE_FETCH_COMPETENCES_SUCCESS:
    return {
      ...state,
      competences: {
        ...state.competences,
        isFetching: false,
        data: action.payload.competences,
        updated: new Date(),
      },
    };
  case profileActions.PROFILE_FETCH_COMPETENCES_FAILURE:
    return {
      ...state,
      competences: {
        ...state.competences,
        isFetching: false,
        data: null,
        error: action.payload.error,
        updated: null,
      },
    };
  default:
    return state;
  }
};

export default profile;
