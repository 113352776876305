import {css, jsx} from '@emotion/react';
import styled from '@emotion/styled';


export const Header = styled.h3`
	color: #494949;
	font-weight: 500;
	margin-left: 25px;
	margin-top: 15px;
`;

export const Desc = styled.p`
  margin-left: 25px;
  margin-top: 0;
`;


export const DescriptionEdit = styled.textarea`
  padding: 0.5rem;
  margin-bottom: 30px;
  margin-left: 25px;
  border: 1px solid #c9c9c9;
  width: 80%;
`;

export const TitleEditWrapper = styled.div`
  padding: 4px;
  margin-bottom: 30px;
  margin-left: 25px;
  width: 80%;
`;
