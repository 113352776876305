import styled from '@emotion/styled';
import * as modalStyles from '@components/modal/modal-styles';
import * as formInputStyles from '@components/form-input/form-input-styles';
import {StyledCheckbox} from '@components/checkbox/Checkbox';

export const Wrapper = styled.div`
  .signature-modal {
    padding: 0;

    &__header {
      margin: 0;
      padding: 0;
    }
  }

  .modal__content {
    padding: 0;
  }

  .action-button {
    width: 100%;
    margin-top: 1em;
  }

  ${formInputStyles.InputWrapper} {
    margin: 0;
    padding: 0;

    ${formInputStyles.StyledLabel} { 
      margin: 0;
      padding: 0;
      padding-bottom: 0.5em;
    }
  }

  ${modalStyles.CloseButton} {
    background: transparent;
    color: #000;
    width: 28px;
    height: 28px;
    right: 1em;
    top: 1em;
    z-index: 2;

    &:hover {
      background: rgba(0, 0, 0, 0.1);
    }
  }

  ${modalStyles.ContentWrapper} {
    padding: 0;
    border-radius: 6px;
    max-width: 600px;
  }

  ${modalStyles.TitleWrapper} {
    margin: 0;
    padding: 0;
  }

  ${modalStyles.BodyWrapper} {
    padding: 1.5em 1em;
    margin: 0;
  }

  ${StyledCheckbox} {
    cursor: pointer;

    &:hover {
      border: 1px solid #565656;
    }
  }
`;
