import {applyMiddleware, compose, createStore, combineReducers} from 'redux';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';

import {all} from 'redux-saga/effects';
import {reducer as formReducer} from 'redux-form';
import {
  responsiveStoreEnhancer,
  createResponsiveStateReducer,
} from 'redux-responsive';

import authReducer from '@reducers/auth.reducer';
import alertReducer from '@reducers/alert.reducer';
import coursesReducer from '@reducers/courses.reducer';
import manageReducer from '@reducers/manage.reducer';
import profileReducer from '@reducers/profile.reducer';
import employeesReducer from '@reducers/employees.reducer';
import messagesReducer from '@reducers/messages.reducer';
import rolesReducer from '@reducers/roles.reducer';
import themeReducer from '@reducers/theme.reducer';
import notificationsReducer from '@reducers/notifications.reducer';
import newsReducer from '@reducers/news.reducer';
import configReducer from '@reducers/config.reducers';
import cmsReducer from '@reducers/cms.reducers';
import mapReducer from '@reducers/map.reducers';

import authSagas from '@sagas/auth.sagas';
import competencesSagas from '@sagas/competences.sagas';
import coursesSagas from '@sagas/courses.sagas';
import profileSagas from '@sagas/profile.sagas';
import employeesSagas from '@sagas/employees.sagas';
import manageSagas from '@sagas/manage.sagas';
import messagesSagas from '@sagas/messages.sagas';
import rolesSagas from '@sagas/roles.sagas';
import themeSagas from '@sagas/theme.sagas';
import newsSagas from '@sagas/news.sagas';
import configSagas from '@sagas/config.sagas';
import cmsSagas from '@sagas/cms.sagas';
import mapSagas from '@sagas/map.sagas';
import {size} from '@styles/device';

// from here https://github.com/AlecAivazis/redux-responsive
const defaultBreakpoints = {
  extraSmall: 480,
  small: 768,
  medium: 992,
  mobileCourseCatalog: Number.parseInt(
    size.mobileCourseCatalog.split('px')[0],
    10,
  ),
  large: 1200,
};

export const configureStore = initialState => {
  const reducers = combineReducers({
    auth: authReducer,
    alert: alertReducer,
    courses: coursesReducer,
    profile: profileReducer,
    employees: employeesReducer,
    messages: messagesReducer,
    roles: rolesReducer,
    form: formReducer,
    browser: createResponsiveStateReducer(defaultBreakpoints),
    theme: themeReducer,
    manage: manageReducer,
    notifications: notificationsReducer,
    news: newsReducer,
    config: configReducer,
    cms: cmsReducer,
    map: mapReducer,
  });
  const sagaMiddleware = createSagaMiddleware({
    onError: error => {
      console.error(error);
      store.dispatch({
        type: 'ERROR',
        payload: error,
      });
    },
  });
  const middlewares = [thunk, sagaMiddleware];

  /* eslint no-underscore-dangle: 0 */
  const devTools
    = process.env.NODE_ENV !== 'production' && window.__REDUX_DEVTOOLS_EXTENSION__
      ? window.__REDUX_DEVTOOLS_EXTENSION__({trace: true})
      : undefined;

  const enhancer = devTools
    ? compose(
      applyMiddleware(...middlewares),
      responsiveStoreEnhancer,
      devTools,
    )
    : compose(applyMiddleware(...middlewares), responsiveStoreEnhancer);

  const store = createStore(reducers, initialState, enhancer);

  function* rootSaga() {
    yield all([
      ...authSagas,
      ...coursesSagas,
      ...profileSagas,
      ...employeesSagas,
      ...manageSagas,
      ...competencesSagas,
      ...messagesSagas,
      ...rolesSagas,
      ...themeSagas,
      ...newsSagas,
      ...configSagas,
      ...cmsSagas,
      ...mapSagas,
    ]);
  }
  sagaMiddleware.run(rootSaga);

  return store;
};
