import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {forwardRef} from 'react';
import {ActionButtonStyled, Separator, IconWrapper} from './buttonStyles';
import {useTheme} from '@emotion/react';

import {faChevronRight} from '@fortawesome/pro-regular-svg-icons';

/** *
 * props:
 * variant: controlls the shape and look-and-feel of the button
 * theme: adjusts the colors for a given variant
 */

export const ActionButton = forwardRef(({
  children,
  withRightChrevron,
  disabled,
  withPhosphorIcon,
  placeholderMode, // hide text and icons
  variant = 'default',
  fontSize,
  className,
  ...props
}, ref) => {
  const PhosphorIconComponent = withPhosphorIcon && withPhosphorIcon.icon;
  const theme = useTheme();
  const phosphorIconProps = {
    weight:  withPhosphorIcon?.weight || 'fill',
    color:  theme.buttons[variant || 'default'].iconColor || (['default', 'defaultTheme', 'cancel', 'cancelTheme'].includes(variant) ? 'black' : 'white'),
    fontSize: withPhosphorIcon?.fontSize || '20px',
  };

  const rightChevronProps = {
    icon: faChevronRight,
    css: {
      marginLeft: '0.75em',
      fontSize: '0.8em',
      alignSelf:'center',
    },
  };

  return (
    <ActionButtonStyled
      className={`action-button ${variant || 'default' } ${className || ''}`}
      ref={ref}
      theme={theme}
      variant={variant  || 'default'}
      disabled={disabled}
      fontSize={fontSize}
      hasRightIcon={withRightChrevron && !withRightChrevron.noSeparatorLine || withPhosphorIcon?.position === 'right' || withPhosphorIcon && !withPhosphorIcon.position}
      hasLeftIcon={withPhosphorIcon?.position === 'left'}
      {...props}
    >
      {withPhosphorIcon && withPhosphorIcon.position === 'left'
        &&   !placeholderMode &&      (
          <IconWrapper position="left">
            <Separator />
            <PhosphorIconComponent {...phosphorIconProps}/>
          </IconWrapper>
      )}
      {!placeholderMode && children}

      {withPhosphorIcon && (withPhosphorIcon.position === 'right' || !withPhosphorIcon.position)
        &&         (
          <IconWrapper position="right">
            <Separator />
            <PhosphorIconComponent {...phosphorIconProps}/>
          </IconWrapper>
        )}
      {placeholderMode && (
        <span
          aria-hidden="true"
          style={{color: 'transparent'}}
        >
          {/* display some invisible text to take up space */}
          Test1234567
        </span>
      )}
      {withRightChrevron && !placeholderMode && !withRightChrevron.noSeparatorLine && (
        <IconWrapper position="right">
          <Separator />
          <FontAwesomeIcon
            {...rightChevronProps}
          />
        </IconWrapper>
      )}
      {withRightChrevron && !placeholderMode && withRightChrevron.noSeparatorLine && (
        <FontAwesomeIcon
          {...rightChevronProps}
        />
      )}
    </ActionButtonStyled>
  );
});
