import {
  getConfigObject,
  getPropertiesForCurrLangAndTrack,
} from './config.selectors';

import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';
import _uniqWith from 'lodash/uniqWith';


export const getCompetences = ({courses: {competences}}) => ({
  ...competences,
  data:
    Array.isArray(competences.data)
    && competences.data.map(competence => {
      if (!Array.isArray(competence.files)) {
        return {
          ...competence,
          image: competence.content && competence.content.image,
          contentRoute: competence.url ? `/content/${competence.url}` : null,
        };
      }
      const cover = competence.files.find(file => file.title === 'cover');
      const durations
        = competence.durations
          && competence.durations.length
          && competence.durations[0].duration
        || null; // ToDo: Get dynamic value from API
      const durationType
        = competence.durations
          && competence.durations.length
          && competence.durations[0].type
        || null;

      return {
        ...competence,
        cover,
        image:
          cover && cover.url
          || competence.content && competence.content.image,
        contentRoute: competence.url ? `/content/${competence.url}` : null,
        durations,
        allDurations: competence.durations,
        durationType,
      };
    }),
});

export const getFeeaturedCompetences = state => {
  const featuredCompetences = getCompetences({courses: {competences: state.courses.featuredCompetences}});

  return featuredCompetences;
};

export const getCourseEvents = ({courses: {courseEvents}}) => courseEvents;

export const getCompetencegroups = ({courses: {competencegroups}}) =>
  competencegroups;

export const getCourseCatalogNews = ({courses: {courseCatalogNews}}) =>
  courseCatalogNews;

export const getCompetencetypes = ({courses: {competencetypes}}) =>
  competencetypes;

export const getSelectedCatalogView = ({courses: {filters: {catalogView}}}) => catalogView;

export const getSelectedCourseKindTab = ({courses: {filters: {courseKind:{tab}}}}) => tab;

export const getSelectedCompetencegroupId = ({courses: {selectedCompetencegroupId}}) => selectedCompetencegroupId;

export const getSelectedSubcompetencegroupId = ({courses: {selectedSubcompetencegroupId}}) => selectedSubcompetencegroupId;

export const getSelectedSubSubcompetencegroupId = ({courses: {selectedSubSubcompetencegroupId}}) => selectedSubSubcompetencegroupId;

export const getSigningOnCourse = ({courses: {courseSignOn}}) =>
  courseSignOn;

export const getSigningOffCourse = ({courses: {courseSignOff}}) =>
  courseSignOff;

export const getCompetencesSearchTerm = ({courses: {competences: {searchTerm}}}) => searchTerm;

export const getCoursesSorting = ({courses: {sorting}}) => sorting;

export const getCourseFilters = ({courses}) => courses.filters;

export const getSelectedCompetencetypes = ({courses: {filters: {selectedCompetencetypes}}}) =>
  Object.keys(selectedCompetencetypes).reduce(
    (ids, id) => selectedCompetencetypes[id] && [...ids, id] || ids,
    [],
  );

export const getSelectedCompetencetypesNames = ({courses: {competencetypes, selectedCompetencetypes}}) => competencetypes.data
  ? new Set(competencetypes.data
    .filter(({id}) => selectedCompetencetypes.has(id))
    .map(({competence_type}) => competence_type))
  : new Set();

export const getInitializeMyCoursesView = state => state.courses.initializeMyCoursesView;

export const getCompetenceDetails = ({courses: {competenceDetails}}) => ({
  ...competenceDetails,
  data:
    competenceDetails.data
    && (competence => {
      let cover;
      let duration;

      if (
        Array.isArray(competence.files)
        && competence.files.filter(file => file.title === 'cover').length
      ) {
        cover = competence.files.find(file => file.title === 'cover');
      }
      if (Array.isArray(competence.durations) && competence.durations.length) {
        [duration] = competence.durations;
      }

      return {
        ...competence,
        cover,
        duration: duration && {
          value: duration.duration,
          metric: duration.type,
        },
      };
    })(competenceDetails.data),
});

export const getActiveCourse = ({courses: {activeCourse}}) => activeCourse;

export const getSignOnCourseResults = ({courses: {courseSignOn: {results, isFetching}}}) => ({
  results,
  isFetching,
});

export const getCurrentSignature = ({courses: {currentSignature}}) =>
  currentSignature;

export const getSignCourse = ({courses: {courseSignCourse}}) =>
  courseSignCourse;

export const getFeaturedContentIds = state => {
  const configForCurrLangAndMap = getPropertiesForCurrLangAndTrack(state);
  const configObject = getConfigObject(state);
  const featuredTiles = configObject.getProperty('routes.course-catalog.customToplevelSelector.featuredTiles');
  const featuredTilesForCurrMap = _get(
    configForCurrLangAndMap,
    'courseCatalog.featuredTiles.contentIds',
  );

  const ids = [];

  if (featuredTilesForCurrMap) {
    if (Array.isArray(featuredTilesForCurrMap)) {
      ids.push(featuredTilesForCurrMap);
    } else {
      ids.push([featuredTilesForCurrMap]);
    }
  }
  if (featuredTiles && featuredTiles.contentIds) {
    if (Array.isArray(featuredTiles.contentIds)) {
      ids.push(featuredTiles.contentIds);
    } else {
      ids.push([featuredTiles.contentIds]);
    }
  }

  return _uniqWith(ids, _isEqual);
};
