import React from 'react';
import styled from '@emotion/styled';
import {css, jsx} from '@emotion/react';

const CheckboxContainer = styled('div')({
  display: 'inline-block',
  verticalAlign: 'middle',
});

const Icon = styled('svg')({
  fill: 'none',
  stroke: 'white',
  strokeWidth: '2px',
});

const HiddenCheckbox = styled('input')({
  border: 0,
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  margin: -1,
  overflow: 'hidden',
  padding: 0,
  position: 'absolute',
  whiteSpace: 'nowrap',
  width: 1,
});

export const StyledCheckbox = styled('div')({
  display: 'inline-block',
  width: 16,
  height: 16,
  borderRadius: 3,
  transition: 'all 150ms',
}, props => ({
  background: props.checked ? props.backgroundColorChecked ?? 'salmon' : props?.backgroundColor ?? 'papayawhip',
  border: props.checked ? props.borderChecked ?? 'none' : props.border ?? 'none',
  Icon: {visibility: props.checked ? 'visible' : 'hidden'},
}));

export const Checkbox = ({className, checked, viewBox, ...props}) => (
  <CheckboxContainer className={className}>
    <HiddenCheckbox
      type="checkbox"
      checked={checked}
      {...props}
    />
    <StyledCheckbox
      checked={checked}
      {...props}
    >
      <Icon viewBox={viewBox ?? '0 0 24 24'}>
        <polyline points="20 6 9 17 4 12" />
      </Icon>
    </StyledCheckbox>
  </CheckboxContainer>
);
