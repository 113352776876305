const exportObj = {
  'color-main-dark': '#e00d2e',
  'color-main-dark-2': '#fbbec7',
  'color-main-light': '#ac737c',
  'color-main': '#e00d2e',
  'color-header-top': '#e00d2e',
  'color-header-bottom': '#fff',
  'color-header-text': '#333',
  'color-header-link-underline': '#ef5921',
  'color-link': '#e00',
  'color-input-text': '#333',
  'color-action': '#e00d2e',
};

export default exportObj;
