import PropTypes from 'prop-types';
import React, {useState} from 'react';
import cx from 'classnames';
import {Link} from 'react-router-dom';
import {getRouteWithParams} from '@utils/routes.utils';
import {
  employees as employeeRoutes,
  myEducation,
} from '@routes/routes.manifest';
import avatarFeatures, {getFeatureSourceByIndex} from './avatar-features';
import './person-avatar.scss';


const AvatarLayer = ({data, type}) => {
  const url = getFeatureSourceByIndex({
    data,
    type,
  });

  return (
    url && (
      <span
        className={`person-avatar__avatar-layer person-avatar__avatar-layer-${
          type.id
        }`}
        style={{backgroundImage: `url('${url}')`}}
      />
    )
  );
};

const LinkIf = ({to, href, children, ...props}) => {
  if (to) {
    return (
      <Link
        to={to}
        {...props}
      >
        {children}
      </Link>
    );
  }
  if (href) {
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        {...props}
      >
        {children}
      </a>
    );
  }

  return children;
};

const PersonAvatar = ({
  imageUrl,
  editable,
  size,
  link,
  to,
  href,
  person,
  className,
  style,
  fontSize,
  avatar: hasAvatar,
  notificationCount,
  isFetching,
  ...restProps
}) => {
  const getPersonInitials = () => {
    const initials = [];

    if (person) {
      if (person.firstname) {
        initials.push(person.firstname[0]);
      }
      if (person.lastname) {
        initials.push(person.lastname[0]);
      }
      if (!initials.length && person.fullname) {
        return person.fullname.split(' ').map(name => name[0]);
      }
    }
    if(isFetching) {
      initials.push('A', 'A');
    }

    return initials.join('').toUpperCase();
  };

  const getPersonColor = () => 'rgb(210,210,210)';

  const avatarUrl
    = imageUrl
    || person && person.profile_image && person.profile_image.url
    || undefined;
  const initialsProps
    = fontSize && {style: {fontSize}}
    || {};

  let avatar = hasAvatar && (person && person.avatar);

  if (avatar) {
    try {
      avatar = JSON.parse(avatar);
    } catch {
      avatar = null;
    }
  }

  const hasImage = avatarUrl && !avatar;

  const personInitials = !hasImage && getPersonInitials();
  const personColor = !hasImage && getPersonColor();

  const clickable = link || to || editable;

  const hoverStyle = {
    backgroundColor: personColor,
    cursor: 'pointer',
  };

  return (
    <LinkIf
      to={
          to
          || to !== false
            && (editable && myEducation.profileView.path
              || person
                && link
                && getRouteWithParams(employeeRoutes.employeePreview, {userName: person.user_name}))
        }
      href={href}
    >
      <div
        className={cx('person-avatar', className)}
        style={{
          ...style || {},
          ...size && {
            width: size,
            height: size,
          } || {},
        }}
      >
        <div
          className={cx('person-avatar__image')}
          title={
              editable && 'Rediger profil' || person && person.fullname
            }
          style={{
            backgroundImage: hasImage && `url(${avatarUrl})` || 'none',
            backgroundColor: personColor,
            ...clickable && hoverStyle || {},
          }}
          {...restProps}
        >

          {avatar && (
            <span className="person-avatar__avatar">
              <AvatarLayer
                data={avatar}
                type={avatarFeatures.head}
              />
              <AvatarLayer
                data={avatar}
                type={avatarFeatures.body}
              />
              <AvatarLayer
                data={avatar}
                type={avatarFeatures.eyes}
              />
              <AvatarLayer
                data={avatar}
                type={avatarFeatures.mouth}
              />
              <AvatarLayer
                data={avatar}
                type={avatarFeatures.beard}
              />
              <AvatarLayer
                data={avatar}
                type={avatarFeatures.eyeWear}
              />
              <AvatarLayer
                data={avatar}
                type={avatarFeatures.hair}
              />
              <AvatarLayer
                data={avatar}
                type={avatarFeatures.hat}
              />
            </span>
          ) || !hasImage && (
            <span
              className="person-avatar__initials"
              {...initialsProps}
            >
              {personInitials}
            </span>
          )}
        </div>

        {!!notificationCount && (
          <div className="person-avatar__notification-count person-avatar__notification-count-enter-done">
            {notificationCount}
          </div>
        )}
      </div>
    </LinkIf>
  );
};

PersonAvatar.propTypes = {
  person: PropTypes.shape({}).isRequired,
  imageUrl: PropTypes.string,
  size: PropTypes.string,
  fontSize: PropTypes.string,
  editable: PropTypes.bool,
  avatar: PropTypes.bool,
  link: PropTypes.bool,
  notificationCount: PropTypes.number,
  to: PropTypes.string,
  href: PropTypes.string,
  noBackground: PropTypes.bool,
};

PersonAvatar.defaultProps = {
  imageUrl: undefined,
  size: undefined,
  fontSize: undefined,
  editable: false,
  avatar: false,
  link: false,
  notificationCount: 0,
  to: null,
  href: null,
  noBackground: false,
};

export default PersonAvatar;
