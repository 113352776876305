const exportObj = {
  'color-main-dark': '#131f0e',
  'color-main-dark-2': '#1f3517',
  'color-main-light': '#8b9e73',
  'color-main': '#78be21',
  'color-header-top': '#78be21',
  'color-header-bottom': '#131f0e',
  'color-header-text': '#fff',
  'color-header-link-underline': '#ef5921',
  'color-link': '#333',
  'color-input-text': '#cfcfbf',
  'color-action': '#335525',
};

export default exportObj;
