import {profileAddCv, profileEditCv, profileFetchCv, profileRemoveCv} from '@actions/profile.actions';
import {getCVsFull, getProfile} from '@selectors/profile.selectors';
import React, {useEffect, useState, useMemo} from 'react';
import {css} from '@emotion/react';
import {myEducation as myEducationRoutes} from '@routes/routes.manifest';
import {useDispatch, useSelector} from 'react-redux';

import {Wrapper, ContentWrapper} from './cv-styles';
import {InfoCollection} from './cv-components';

import {CVView} from '../../../../common/components/cvs/view/cv-view';
import {CVEdit} from '../../../../common/components/cvs/edit/cv-edit';
import {withRouter} from 'react-router';
import {getRouteWithParams} from '@utils/routes.utils';
import {i18n} from '@src/i18n';
import Modal from '@components/modal/modal';
import {notificationsAdd} from '@actions/notifications.actions';
import  {AnimateSlideUpOnLoadTransparent} from '@components/animate-slide-up-on-load/AnimateSlideupOnLoad';
import {useWindowDimensions} from '@src/hooks/hooks';
import {CloseButton} from '@components/buttons/closeButton';
import {prepareCvData} from '@components/cvs/util';


export const CvContainer = withRouter(({match, location, history}) => {
  const dispatch = useDispatch();
  const action = location.pathname.includes('/add-cv') ? 'add' : 'edit';

  const CVs = useSelector(getCVsFull);
  const cvId = match.params.cvId;
  const CV = useMemo(() => action === 'edit' ?  CVs.data && !CVs.isFetching && CVs.data[cvId] : {experiences: []}, [action, CVs.isFetching, CVs.data, cvId]);

  const {height: windowHeight} = useWindowDimensions({useMaxInnerOuterHeight: true});

  const profile = useSelector(getProfile);

  const [mode, setMode] = useState(action === 'add' ? 'add' : 'view');
  const [showDeleteCvModal, setShowDeleteCvModal] = useState(false);

  const [showCvList, setShowCvList] = useState(true);

  const toggleManageMode = () => {
    setMode(old => old === 'view' ? 'edit' : 'view');
  };

  const addCv = ({description, title, experienceData}) => {
    dispatch(profileAddCv({
      data: {
        experiences: Object.values(experienceData).flat(),
        description,
        title,
      },
      onSuccessCallback: createdCv => {
        history.push(getRouteWithParams(myEducationRoutes.cvEdit.path, {
          personId: profile.data.person_id,
          cvId: createdCv.id,
        }));
      },
    }));
    setMode('view');
  };

  const editCv = ({description, title, experienceData}) => {
    dispatch(profileEditCv({
      data: {
        experiences: Object.values(experienceData).flat(),
        description,
        title,
      },
      cvId,
    }));
    setMode('view');
  };

  const removeCv = () => {
    history.push(getRouteWithParams(
      myEducationRoutes.cvView.path,
      {personId: profile.id},
    ));
    dispatch(profileRemoveCv({
      cvId,
      onSuccessCallback: createdCv => {
        dispatch(notificationsAdd({
          notification: {
            text:i18n('cv.cv-delete-success'),
            color: 'green',
          },
        }));
      },
      onFailureCallback: () => {
        notificationsAdd({
          notification: {
            text:i18n('cv.cv-delete-failure'),
            color: 'red',
          },
        });
      },
    }));
  };

  const showRemoveDialog = () => {
    setShowDeleteCvModal(true);
  };

  const gotoMyCompetences = () => {
    setShowCvList(false);
    setTimeout(() => {
      history.push(getRouteWithParams(myEducationRoutes.cvView.path, {personId: profile.data.id}));
    }, 500);
  };

  useEffect(() => {
    if(action === 'edit' && !CV && !CVs.isFetching && !CVs.error) {
      dispatch(profileFetchCv({cvId}));
    }
  }, [action, CV, CVs, CVs.data, CVs.error, CVs.isFetching, cvId, dispatch]);


  const isFetching = (CVs.isFetching || !CVs.data  || !profile.data || !CV) && action === 'edit';

  return (
    <AnimateSlideUpOnLoadTransparent
      css={css`overflow: auto;`}
      show={showCvList}
    >
      <Wrapper
        mode={mode}
        css={css`height: ${mode === 'edit' ? 'fit-content' : 'fit-content'};`}
      >
        <CloseButton
          className="close-btn"
          variant="lightGray"
          onClick={gotoMyCompetences}
          iconVariant="regular"
          style={{position: 'absolute'}}
        />
        {CVs.error && (
          <InfoCollection mode={mode}>
            <ContentWrapper>{i18n('cv.cv-fetch-failure', {ellipsis: true})}</ContentWrapper>
          </InfoCollection>
        )
      || (profile.data && CV || isFetching) && (
        <>
          <InfoCollection mode={mode}>
            {mode === 'view' || isFetching
              ? (
                <CVView
                  isFetching={isFetching}
                  experienceData={CV}
                  profile={profile}
                  fullwidth
                  action={action}
                  experiencesDividedByType={!isFetching && prepareCvData(CV)}
                  description={CV && CV.description}
                  title={CV && CV.title}
                  onEnterEditMode={toggleManageMode}
                  onRemoveCv={showRemoveDialog}
                />
              )
              : (
                <CVEdit
                  initialExperienceData={prepareCvData(CV)}
                  profile={profile}
                  initialDescription={CV.description}
                  initialTitle={CV.title}
                  onSave={({description, experienceData, title}) =>
                    action === 'add'
                      ? addCv({
                        description,
                        title,
                        experienceData,
                      })
                      : editCv({
                        description,
                        title,
                        experienceData,
                      })}
                  cvID={CV.id}
                />
              )}
          </InfoCollection>

          {showDeleteCvModal && (
            <Modal
              variant="warning"
              title={() => <Modal.Title>{i18n('globals.you-sure')}</Modal.Title>}
              submitButtonText={i18n('globals.delete')}
              onConfirm={removeCv}
              onCancel={() => setShowDeleteCvModal(false)}
              onClose={() => setShowDeleteCvModal(false)}
            >
              {i18n('cv.confirm-delete-cv')}
            </Modal>
          )}
        </>
      )}
      </Wrapper>
    </AnimateSlideUpOnLoadTransparent>

  );
});


