import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import DatePicker from 'react-widgets/DatePicker';
import {StyledLabel, InputWrapper, DatePickerWrapper, StyledErrorMsg} from '@components/form-input/form-input-styles';
import moment from 'moment';
import {standardDateFormat} from '@src/i18n';
import {useSelector} from 'react-redux';
import {getIsMobile} from '@selectors/global.selectors';
import 'react-widgets/styles.css';

const FormDatePicker = ({className, input, label, meta, ...props}) => {
  const isMobile =  useSelector(getIsMobile);

  return (
    <InputWrapper>
      {label && (
        <StyledLabel
          htmlFor={input.name}
        >
          {label}
        </StyledLabel>
      )}
      <DatePickerWrapper
        error={meta.touched && meta.error}
        isMobile={isMobile}
      >
        <DatePicker
          {...props}
          {...input}
          id={input.name}
          value={input.value ? moment(input.value, standardDateFormat).isValid() && moment(input.value, standardDateFormat).toDate()  || null : null}
          valueFormat="DD.MM.YYYY"
          includeTime={false}
          containerClassName="datepicker-input"
          className={classnames('', className, {'form-input__input--error': meta.touched && meta.error})}
        />
      </DatePickerWrapper>
      {meta.touched && meta.error
      &&       (
        <StyledErrorMsg role="alert">
          {meta.error}
        </StyledErrorMsg>
      )}
    </InputWrapper>
  );
};

FormDatePicker.propTypes = {
  className: PropTypes.string,
  input: PropTypes.shape({}).isRequired,
  label: PropTypes.string,
  meta: PropTypes.shape({
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    touched: PropTypes.bool,
  }).isRequired,
};

FormDatePicker.defaultProps = {
  className: '',
  label: '',
};

export default FormDatePicker;
