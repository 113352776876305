import {takeLatest, put, all, call} from 'redux-saga/effects';
import axios from 'axios';

import {backendUrl} from '@config';

import {authUnauthorized} from '@actions/auth.actions';
import {
  MESSAGES_GET_MESSAGES,
  messagesGetRequest,
  messagesGetSuccess,
  messagesGetFailure,
  MESSAGES_SEND_MESSAGE,
  messagesSendMessagePostRequest,
  messagesSendMessagePostSuccess,
  messagesSendMessagePostFailure,
} from '@actions/messages.actions';
import {notificationsAdd} from '@actions/notifications.actions';

import retry from '@utils/sagas.utils';
import {i18n} from '@src/i18n';

export function* getMessages() {
  yield put(messagesGetRequest());

  try {
    const {data: {messages}} = yield retry(() =>
      axios.request({
        method: 'GET',
        params: {fields: 'ts,message_id'},
        url: `${backendUrl}/api/messages`,
        withCredentials: true,
      }));

    //
    const messages_mock  = JSON.parse(`[
        {
          "files": null,
          "is_sms": 0,
          "id": null,
          "message_id": 1234,
          "person": {
            "email": "erlend@snapper.no",
            "fullname": "Erlend Østlie",
            "id": 139780
          },
          "recipient_address": "erlend@snapper.no",
          "recipient_name": "Erlend Østlie",
          "reply_to": null,
          "sender_name": "Anonymous Anonymous",
          "subject": "Glemt passord",
          "text": "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse molestie suscipit eros ac vehicula. Curabitur non urna vestibulum, rhoncus sem nec, pretium ante. Phasellus imperdiet lorem in bibendum rhoncus. Maecenas eu mollis magna. Nulla pharetra sem eget urna molestie posuere. Nam ut mi ligula. Morbi eros libero, ornare eget mollis vehicula, tincidunt eget tellus. Vestibulum rutrum dui eget ligula iaculis ultricies. Maecenas et elit feugiat, accumsan libero sed, maximus libero. Mauris sodales arcu risus, id imperdiet arcu rutrum sit amet.</p>",
          "ts": "2021-06-09 10:35:03"
        },
        {
          "files": null,
          "is_sms": 0,
          "id": null,
          "message_id": 1234,
          "person": {
            "email": "erlend@snapper.no",
            "fullname": "Erlend Østlie",
            "id": 139780
          },
          "recipient_address": "erlend@snapper.no",
          "recipient_name": "Erlend Østlie",
          "reply_to": null,
          "sender_name": "Anonymous Anonymous",
          "subject": "Mail2",
          "text": "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse molestie suscipit eros ac vehicula. Curabitur non urna vestibulum, rhoncus sem nec, pretium ante. Phasellus imperdiet lorem in bibendum rhoncus. Maecenas eu mollis magna. Nulla pharetra sem eget urna molestie posuere. Nam ut mi ligula. Morbi eros libero, ornare eget mollis vehicula, tincidunt eget tellus. Vestibulum rutrum dui eget ligula iaculis ultricies. Maecenas et elit feugiat, accumsan libero sed, maximus libero. Mauris sodales arcu risus, id imperdiet arcu rutrum sit amet.</p>",
          "ts": "2021-06-09 10:35:03"
        }
      ]`);

    yield put(messagesGetSuccess({messages}));
  } catch (error) {
    console.error(error);
    if (error.status === 401) {
      yield put(authUnauthorized({error}));
    }
    yield put(messagesGetFailure({error}));
  }
}

function* sendMessage(action) {
  const {username: user_name, title, emailBody, callback} = action.payload;

  yield put(messagesSendMessagePostRequest());

  try {
    yield all({
      email: call(() =>
        axios.request({
          method: 'POST',
          url: `${backendUrl}/api/messages`,
          params: {
            user_name,
            title,
            text: emailBody,
            send_medium: 'email',
          },
          withCredentials: true,
        })),
    });

    yield callback({employee: {user_name}});

    yield put(messagesSendMessagePostSuccess());
    yield put(notificationsAdd({
      notification: {
        text: i18n('message.message-sent-success'),
        color: 'green',
      },
    }));
  } catch (error) {
    console.error(error);
    if (error.status === 401) {
      yield put(authUnauthorized({error}));
    }
    yield put(messagesSendMessagePostFailure({error}));
    yield put(notificationsAdd({
      notification: {
        text:  i18n('globals.error'),
        color: 'red',
      },
    }));
    yield callback({employee: {user_name}});
  }
}

const exportObj = [
  takeLatest(MESSAGES_GET_MESSAGES, getMessages),
  takeLatest(MESSAGES_SEND_MESSAGE, sendMessage),
];

export default exportObj;
