import React, {useCallback, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {
  coursesSignCourse,
  coursesBeginSignature,
} from '@actions/courses.actions';
import {fetchedDataWrapperShape} from '@types/fetched-data';
import {
  getCurrentSignature,
  getSigningOnCourse,
} from '@selectors/courses.selectors';
import Modal from '@components/modal/modal';
import FormInput from '@components/form-input';
import './signature-modal.scss';
import {i18n} from '@src/i18n';
import {css} from '@emotion/react';
import {Wrapper} from './signature-modal.styles';
import {ActionButton} from '@components/buttons/actionButton';
import {Checkbox} from '@components/checkbox';


const SignatureModal = ({
  course,
  coursesSignCourse,
  coursesBeginSignature,
  signWithPassword = true,
}) => {
  if (!course) {
    return null;
  }

  const [readyToSign, setReadyToSign] = useState(false);

  const [formData, setFormData] = useState({
    courseId: course?.id,
    password: '',
    checked: !signWithPassword,
  });

  const handleInput = useCallback(({target: {id, value}}) => {
    if (!value) {
      setReadyToSign(false);
    } else {
      if (!readyToSign) {
        setReadyToSign(true);
      }
    }
    setFormData(prev => ({
      ...prev,
      [id]: value,
    }));
  }, [readyToSign]);


  return (
    <Modal
      onClose={() => coursesBeginSignature(null)}
      WrapperComponent={Wrapper}
      disableActionButtons
    >
      <div className="signature-modal">
        <div className="signature-modal__header">
          {`${course.title || course.competence_title}`}
        </div>

        {course.description && (
          <div css={css({padding: '1em 0'})}>
            {course.description}
          </div>
        )}

        {!signWithPassword && (
          <div css={css({padding: '1.5em 0'})}>
            <span
              css={{
                fontWeight: 600,
                fontSize: '16px',
              }}
            >
              {i18n('competence.confirm-to-sign')}
            </span>
            <Checkbox
              checked={readyToSign}
              onClick={() => setReadyToSign(prev => !prev)}
              viewBox="0 0 24 32"
              style={{
                marginTop: '2px',
                marginLeft: '8px',
                overflow: 'hidden',
              }}
              backgroundColor="#fff"
              backgroundColorChecked="#0070F3"
              border="1px solid #A2A3A2"
              borderChecked="1px solid #0070F3"
            />
          </div>
        )}

        <div>
          {signWithPassword && (
            <FormInput
              className="person-form__input"
              name="password"
              label={i18n('competence.password')}
              input={{}}
              value={formData.password}
              meta={{}}
              type="password"
              id="password"
              onChange={handleInput}
            />
          )}
          <ActionButton
            key="sign"
            variant={readyToSign ? 'primary' : 'disabled'}
            disabled={!readyToSign}
            style={!signWithPassword ? {marginTop: '0.5em'} : null}
            onClick={() => coursesSignCourse(formData)}
          >
            {i18n('competence.sign')}
          </ActionButton>
        </div>
      </div>
    </Modal>
  );
};


SignatureModal.propTypes = {
  course: PropTypes.shape({}).isRequired,
  signedCourse: fetchedDataWrapperShape(PropTypes.shape({})).isRequired,
  signOnCourseResults: PropTypes.arrayOf(PropTypes.shape({
    correct: PropTypes.arrayOf({
      message: PropTypes.string,
      user: PropTypes.string,
    }),
    errors: PropTypes.arrayOf({
      message: PropTypes.string,
      user: PropTypes.string,
    }),
  })),
  coursesBeginSignature: PropTypes.func.isRequired,
  signWithPassword: PropTypes.bool,
};

const mapStateToProps = state => ({
  course: getCurrentSignature(state),
  signedCourse: state.courses.courseSignCourse,
  courseSigningOn: getSigningOnCourse(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      coursesSignCourse,
      coursesBeginSignature,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SignatureModal);
