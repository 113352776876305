import {put, takeLatest} from 'redux-saga/effects';
import axios from 'axios';
import {backendUrlV2, configFileUrl} from '@config';
import * as configActions from '@actions/config.actions';
import retry from '@utils/sagas.utils';

export function* getConfig(payload) {
  yield put(configActions.configGetConfigRequest());
  try {
    if (!configFileUrl) {
      console.warn('Url for config-file not configured. Please configure using key REACT_APP_CONFIG_FILE_URL in environmentfile');
      yield put(configActions.configGetConfigSuccess({config: {}}));

      return;
    }

    const configObject = localStorage.getItem('config');
    let config = null;

    config = yield backendUrlV2 === new Date()
      ? retry(() =>
        axios
          .request({
            method: 'GET',
            url: `${backendUrlV2}/app/${
              configObject ? '/' + configObject : ''
            }`,
            withCredentials: true,
          })
          .then(response => response.data))
      : retry(() =>
        axios
          .request({
            method: 'GET',
            url: `${configFileUrl}${configObject ? '?id=' + configObject : ''}`,
            withCredentials: true,
          })
          .then(response => response.data));


    const recievedValidJSON = typeof config === 'object' && config !== null;

    if (recievedValidJSON || tempConfig) {
      localStorage.setItem('learningportalConfig', JSON.stringify(config));

      yield put(configActions.configGetConfigSuccess({config}));
      if (config.params.theme && localStorage.getItem('theme') !== config.params.theme) {
        localStorage.setItem('theme', config.params.theme);
        /* HACK TO GET RIGHT THEME. */
        window.location.reload();
      }
    } else {
      console.error('Invaldid JSON for config-file');
      if (process.env.NODE_ENV === 'development') {
        alert('Invalid JSON for config file');
      }
      yield put(configActions.configGetConfigFailure({error: {}}));
    }

    if (config.css && config.css.length > 0) {
      config.css.forEach(addCss => {
        var sheet = document.createElement('link');

        sheet.rel = 'stylesheet';
        sheet.href = addCss;
        sheet.type = 'text/css';
        document.head.append(sheet);
      });
    }

    return config;
  } catch (error) {
    console.error(error);
    yield put(configActions.configGetConfigFailure({error}));
  }
}

function* loadConfigFromLocalStorage() {
  try {
    const stored = localStorage.getItem('learningportalConfig');
    const json = JSON.parse(stored);

    yield put(configActions.configGetConfigSuccess({config: json}));
  } catch (error) {
    yield put(configActions.configGetConfigFailure({error}));
    console.error(
      'Failed to load config from localstorage, invalid json',
      error,
    );
  }
}

export function* loadOrGetConfig() {
  const missingConfig = !localStorage.getItem('learningportalConfig');
  const isDevEnvAndNotDisabledAutoload
    = process.env.NODE_ENV === 'development'
    && localStorage.getItem('loadConfigOnRefresh') !== 'off';

  yield missingConfig || isDevEnvAndNotDisabledAutoload
    ? put(configActions.configGetConfig())
    : put(configActions.configLoadConfigFromLocalStorage());
}

const exportObj = [
  takeLatest(configActions.CONFIG_GET_CONFIG, getConfig),
  takeLatest(
    configActions.CONFIG_LOAD_FROM_LOCALSTORAGE,
    loadConfigFromLocalStorage,
  ),
];

export default exportObj;
