export const MANAGE_ROLES_GET = 'MANAGE_ROLES_GET';
export const MANAGE_ROLES_GET_REQUEST = 'MANAGE_ROLES_GET_REQUEST';
export const MANAGE_ROLES_GET_SUCCESS = 'MANAGE_ROLES_GET_SUCCESS';
export const MANAGE_ROLES_GET_FAILURE = 'MANAGE_ROLES_GET_FAILURE';

export const fetchManageRoles = payload => ({
  type: MANAGE_ROLES_GET,
  payload,
});

export const fetchManageRolesRequest = () => ({type: MANAGE_ROLES_GET_REQUEST});

export const fetchManageRolesSuccess = payload => ({
  type: MANAGE_ROLES_GET_SUCCESS,
  payload,
});

export const fetchManageRolesFailure = payload => ({
  type: MANAGE_ROLES_GET_FAILURE,
  payload,
});

