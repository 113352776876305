import no from './languages/no.json';
import en from './languages/en.json';
import moment from 'moment';
import 'moment/locale/nb';
import 'moment/locale/en-gb';

import _get from 'lodash/get';

const strMap = {
  no,
  en,
};

export const MISSING_TRANSLATION = '[missing translation]';

const loggedErrorsFor = {};

const getCurrLang = () =>  localStorage.getItem('language')
|| (JSON.parse(localStorage.getItem('learningportalConfig')) || {} .params || {})['default-language']
|| 'no';

/**
 * Gets translated strings. Strings are retrieved from i18n/languages/*-json. Current language is retrieved automatically from localStorage or configFile.
 *
 * Examples:
 *
 *  i18n('name') -> gets the translated string for 'name'
 *
 *  i18n('name', {'lowerCase': true}) -> gets the translated string for 'name' in lower case
 *
 *  i18n('name', {'ellipsis': true}) -> gets the translated string for 'name' with ellipsis: "..." added at the end
 *
 *  i18n('a.b') -> gets the translated string from strings -> a -> b
 *
 *  i18n('happy_birthday_to_x', {functionArgs: {'x':'Test mc. Test' } }) -> applies the placeholder values to the string
 *
 * If not found, defaultValue-parameter or '[missing translation]' is returned.
 */

export const i18n = (key, options, defaultValue = '') => {
  const lang = getCurrLang();
  const compoundKey = key.includes('.');
  let target = compoundKey ? _get(strMap[lang], key) : strMap[lang] && strMap[lang][key];

  if (options && options.functionArgs && target) {
    Object.keys(options.functionArgs).forEach(op => {
      target = target.replace('[' + op + ']', options.functionArgs[op]);
    });
  }else if (typeof target === 'string' && options) {
    if (options.lowerCase) {
      target = target && target.toLocaleLowerCase();
    }else if (options.upperCase) {
      target = target && target.toLocaleUpperCase();
    }

    if(options.ellipsis) {
      target = lang === 'no' ? `${target} ...` : `${target}...`;
    }

    return target;
  }

  if (!target) {
    if (!loggedErrorsFor[`${lang}-${key}`]) {
      console.error(`[i18n] Missing i18n for key ${key} and lang ${lang}`);
      loggedErrorsFor[`${lang}-${key}`] = true;
    }
    if (defaultValue) {
      return defaultValue;
    }

    return MISSING_TRANSLATION + ' - ' + key;
  }

  return target;
};


export const i18nCourseGetCompetenceType = course => i18n(`globals.course-types.${course.competence_type.competence_type}`, {}, course.competence_type.title);

export const i18nFormatTimeRange = ({from, to}) => {
  const lang = getCurrLang();


  const formatTimePart = timePart => timePart.toString().padStart(2, '0');

  if(lang === 'no') {
    return `kl. ${formatTimePart(from.hours)}:${formatTimePart(from.minutes)} - ${formatTimePart(to.hours)}:${formatTimePart(to.minutes).toString()}`;
  } else if(lang === 'en') {
    return `${formatTimePart(from.hours)}:${formatTimePart(from.minutes)} - ${formatTimePart(to.hours)}:${formatTimePart(to.minutes).toString()}`;
  }

  return '';
};

export const i18nFrmatDate = date => {
  const lang = getCurrLang();
  const dateObj = date instanceof Date ? date : moment(date).toDate();

  if(lang === 'no') {
    return `${dateObj.getDate()}/${dateObj.getMonth() + 1}-${dateObj.getFullYear().toString()
      .slice(2, 4)}`;
  } else if(lang === 'en') {
    return `${dateObj.getDate()}/${dateObj.getMonth() + 1}-${dateObj.getFullYear().toString()
      .slice(2, 4)}`;
  }
};


/**
 * example(language is "en")
 *
 * 18nFormatTimeAgo('hour', 5) -> 5 hours ago
 */
export const i18nFormatTimeAgo = (timeUnit, val) => {
  const lang = getCurrLang();

  if(lang === 'no') {
    switch (timeUnit) {
    case 'hour': {
      return `${val} time${val !== 1 ? 'r' : ''} siden`;
    }
    case 'minute': {
      return `${val} minutt${val !== 1 ? 'er' : ''} siden`;
    }
    case 'day': {
      return `${val} dag${val !== 1 ? 'er' : ''} siden`;
    }
    case 'lessThanMinute':
      return '< 1 minutt siden';
    default:
      return `[i18n] i18nFormatTimeAgo not configured for ${timeUnit} and ${lang}`;
    }
  } else if(lang === 'en') {
    switch (timeUnit) {
    case 'hour': {
      return `${val} hour${val !== 1 ? 's' : ''} ago`;
    }
    case 'minute': {
      return `${val} minute${val !== 1 ? 's' : ''} ago`;
    }
    case 'day': {
      return `${val} day${val !== 1 ? 's' : ''} ago`;
    }
    case 'lessThanMinute':
      return '< 1 minute ago';
    default:
      return `[i18n] i18nFormatTimeAgo not configured for ${timeUnit} and ${lang}`;
    }
  }
};


/**
 * Create a new moment-instance, localized for the current language.
 * Any arguments are passed down to moment()
 */

export const i18nMoment = (...args) => {
  const lang = getCurrLang();
  const momentLocale = {
    no:'nb',
    en:'en-gb',
  }[lang];

  moment.locale(momentLocale);

  return moment(...args);
};


const isInvalidDate = obj => obj && obj.getTime && Number.isNaN(obj.getTime());

const dateFormatsByLang = {
  no:'DD.MM.YYYY',
  en:'MM/DD/YY',
};

export const standardDateFormat = 'DD.MM.YYYY';

export const i18nLocaleDateStrToStdDateStr = date => {
  // const lang = getCurrLang();
  const lang = 'no';

  const getDateByLocale = () => {
    const m = moment(date, dateFormatsByLang[lang]);

    if(!m.isValid()) {
      return null;
    }

    return m.format(standardDateFormat);
  };


  if(date === null || isInvalidDate(date)) {
    return null;
  }
  if(date === '') {
    return '';
  }

  if(date && date instanceof Date) {
    const m = moment(date, dateFormatsByLang[lang]);

    if(!m.isValid()) {
      return null;
    }

    return m.format(standardDateFormat);
  }

  return getDateByLocale();
};


export const i18nFormatDate = dateStr => {
  const hasTimePart = dateStr.match('[0-9]{4}-{0-9}{2}-[0-9]{2} [0-9]{2}:[0-9]{2}:[0-9]{2}');

  const dateMoment = moment(dateStr, 'YYYY-MM-DD' + (hasTimePart ? ' hh:mm:ss' : ''));
  const lang = getCurrLang();

  return dateMoment.format('DD.MM.YYYY');
};
